import React, { useEffect, useState, memo } from "react";
import UserList from "../../Pages/Board/UserListItem";
import { Jobs } from "../../Pages/Driver/components/Jobs";
import { useSelector } from "react-redux";
import { LoadItem } from "../../Pages/Board/LoadItem";
import DriversList from "../../Pages/Driver/DriversList";

export const DragPreview = memo(
  ({ item, isOverTarget, previewType, itemType }) => {
    const multiSelectDriver = useSelector(
      (state) => state.mainReducer.multiSelectDriver
    );

    const styles = {
      display: "block",
      transform:
        (itemType === "driver" && multiSelectDriver.length > 1) || isOverTarget
          ? ""
          : `rotate(7deg)`,
      WebkitTransform:
        (itemType === "driver" && multiSelectDriver.length > 1) || isOverTarget
          ? ""
          : `rotate(7deg)`,
    };
    const [tickTock, setTickTock] = useState(false);
    useEffect(
      function subscribeToIntervalTick() {
        const interval = setInterval(() => setTickTock(!tickTock), 500);
        return () => clearInterval(interval);
      },
      [tickTock]
    );

    const previewRender = () => {
      switch (previewType) {
        case "user":
          return (
            <UserList
              user={item}
              index={item.index}
              isOverTarget={isOverTarget}
              previewList={true}
            />
          );
        case "load":
          return <LoadItem load={item} index={item.index} previewList={true} />;
        case "driver": // for multi driver
          return (
            <DriversList
              driver={item}
              index={item.index}
              isOverTarget={isOverTarget}
              previewList={true}
            />
          );
        case "job":
          return <Jobs job={item} index={item.index} previewList={true} />;
        default:
          return null;
      }
    };
    return <div style={styles}>{previewRender()}</div>;
  }
);
