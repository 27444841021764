var moment = require("moment-timezone");
// const momentTZ = require("moment-timezone");

export const hourOptions = [
  { label: "00", value: 0 },
  { label: "01", value: 1 },
  { label: "02", value: 2 },
  { label: "03", value: 3 },
  { label: "04", value: 4 },
  { label: "05", value: 5 },
  { label: "06", value: 6 },
  { label: "07", value: 7 },
  { label: "08", value: 8 },
  { label: "09", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
];
export const get_time_diff = (date1, date2) => {
  let date_diff = new Date(date2).getTime() - new Date(date1).getTime();
  let days = Math.floor(date_diff / 1000 / 60 / 60 / 24);
  date_diff -= days * 1000 * 60 * 60 * 24;
  let hh = Math.floor(date_diff / 1000 / 60 / 60);
  date_diff -= hh * 1000 * 60 * 60;
  let mm = Math.floor(date_diff / 1000 / 60);
  date_diff -= mm * 1000 * 60;
  let ss = Math.floor(date_diff / 1000);

  return (days > 0 ? hh + days * 24 : hh) + "h : " + mm + "m : " + ss + "s";
};

export const get_moment_time_diff = (formated, now) =>{
  const givenDate = moment.tz(formated, 'America/Chicago');

  const timeDifference = now.diff(givenDate);

  // Convert the time difference to a human-readable format
  const duration = moment.duration(timeDifference);
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) % 60;
  const seconds = Math.floor(duration.asSeconds()) % 60;
  
  // Output the time difference
  // console.log(`The time difference between current CST and the given date is ${hours} hours, ${minutes} minutes, and ${seconds} seconds.`);
  return hours + "h : " + minutes + "m : " + seconds + "s";
}

export function parseDateCST(date, cst) {
  if(cst){ //convert ISO date into cst 
    return moment.tz(date,"America/Chicago");
  }//convert CST_ISO date into utc 
  return moment.utc(date);
}

export const dynamoCstDateTimeFormat = (date) => {
  if (date !== null && date !== "") {
    let time = moment.tz(date, "America/Chicago").toISOString()
    if(time){
      return time
    }
    return null;
  }
  return null;
};

export function getTimeCST(date, cst) {
  return moment(date).isValid() ? parseDateCST(date, cst).valueOf() : 0;
}

export function readyForLoadTime(user) {
  const { last_delivered_date, checked_time } = user;
  return Math.max(getTimeCST(last_delivered_date), getTimeCST(checked_time));
}
export function calQueueTime(user) {
  const { last_delivered_date, checked_time } = user;
  // var now = new Date().getTime();
  let now = moment().tz("America/Chicago")

  let maxValue = Math.max(
    getTimeCST(last_delivered_date),
    getTimeCST(checked_time)
  );
  // convert maxtimestramp back to moment formated date
  const formated = moment.tz(maxValue, 'America/Chicago').utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
  
  return get_moment_time_diff(formated, now);
}

export function lastUpdatedTime(driver) {
  const { checked_time, last_delivered_date, 
    // shift_changed_time 
  } = driver;
  return Math.max(
    getTimeCST(checked_time),
    getTimeCST(last_delivered_date),
    // getTimeCST(shift_changed_time, true)
  );
}

export function dateConverted(format,date){
  let formattedDate = null;
    switch(format){
      case 'CURRENT_AWSDATETIME': //new date to iso
       formattedDate = new Date().toISOString()
        break;
      case "CURRENT_CST_STRING_FORMAT": //new date string format
        formattedDate = moment().tz("America/Chicago").format('YYYY-MM-DD HH:mm:ss.SSS')
        break;
       case 'CURRENT_CST_AWSDATETIME':
        formattedDate = moment().tz("America/Chicago").toISOString()
        break;
        case 'AWSDATETIME':
          formattedDate = (date && date !== '') ? new Date(date).toISOString() : null; //date to iso
        break;
      default:
        formattedDate = new Date().toISOString()
    }
  return formattedDate
}

export const getHoursDiff = () => {
  const currentDateTimeCST = moment().tz("America/Chicago").format("HH");;
  const utc = moment.utc().format("HH");
  const diff = utc - currentDateTimeCST; 
  if(!isNaN(diff))
    return diff
  return 0
}

export const getActiveShiftOld = (selectedBoardZone) => {
  if(selectedBoardZone !== null && selectedBoardZone !== undefined){
    let amShift = selectedBoardZone.am_shift_start_time;
    let pmShift = selectedBoardZone.pm_shift_start_time;
    if(amShift ===null && pmShift ===null) return 'All'
    let currentTimeInMint = moment().tz("America/Chicago").hour() * 60 + moment().tz("America/Chicago").minute()
    let shift = 'NA';
    // console.log(currentTimeInMint , selectedBoardZone)
    if(amShift && amShift !== null){
      amShift = moment(amShift, 'HH:mm:ss').subtract(getHoursDiff(), 'h');
      let amShiftEnd = (selectedBoardZone.am_shift_buffer_time !== null)? 
        amShift.clone().add(selectedBoardZone.am_shift_buffer_time, 'hours'):
        amShift.clone();
        // console.log('AM',amShift.format("YYYY-MM-DD HH:mm:ss"),amShiftEnd.format("YYYY-MM-DD HH:mm:ss"))
      let amTotalStartMin = amShift.hour() * 60 + amShift.minute()
      let amTotalEndMin = amShiftEnd.hour() * 60 + amShiftEnd.minute()
      // console.log("AM mint",currentTimeInMint, amTotalStartMin, amTotalEndMin)
      if(amTotalEndMin < amTotalStartMin && (
        (amTotalEndMin <= currentTimeInMint && amTotalStartMin <= currentTimeInMint) || 
        (amTotalEndMin >= currentTimeInMint && amTotalStartMin >= currentTimeInMint)
        )){// next day hours added
        console.log('Current time is within AM Shift');
        shift = 'AM'
      }  
      else if (currentTimeInMint >= amTotalStartMin && currentTimeInMint <= amTotalEndMin) {
        console.log('Current time is within AM Shift');
        shift = 'AM'
      }

    }
    if(pmShift && pmShift !==null){
      pmShift = moment(pmShift, 'HH:mm:ss').subtract(getHoursDiff(), 'h');
      let pmShiftEnd = (selectedBoardZone.pm_shift_buffer_time !== null)? 
        pmShift.clone().add(selectedBoardZone.pm_shift_buffer_time, 'hours'):
        pmShift.clone();
        // console.log('PM',pmShift.format("YYYY-MM-DD HH:mm:ss"),pmShiftEnd.format("YYYY-MM-DD HH:mm:ss"))
      let pmTotatStartMin = pmShift.hour() * 60 + pmShift.minute()
      let pmTotalEndMin = pmShiftEnd.hour() * 60 + pmShiftEnd.minute()
      // console.log('PM Mints',currentTimeInMint, pmTotatStartMin, pmTotalEndMin)
      if(pmTotalEndMin < pmTotatStartMin &&  (
        (pmTotalEndMin <= currentTimeInMint && pmTotatStartMin <= currentTimeInMint) || 
        (pmTotalEndMin >= currentTimeInMint && pmTotatStartMin >= currentTimeInMint)
        )){// next day hours added
        console.log('Current time is within PM Shift');
        shift = shift ==='AM'? 'All': 'PM'
      }  
      else if (currentTimeInMint >= pmTotatStartMin && currentTimeInMint <= pmTotalEndMin) {
        console.log('Current time is within PM Shift');
        shift = shift ==='AM'? 'All': 'PM'
      }
    }
    return shift
  }
  return 'All'
} 

export const getActiveShift = (selectedBoardZone) => {
  try{
  let format = "YYYY-MM-DD HH:mm"; // Adjust this format to match your time string format
  let timeZone = 'America/Chicago';

  if(selectedBoardZone !== null && selectedBoardZone !== undefined){
    let amShift = selectedBoardZone.am_shift_start_time;
    let pmShift = selectedBoardZone.pm_shift_start_time;
    
    if(amShift === null && pmShift === null) return 'All';
    const currentDateTimeCST = moment().tz("America/Chicago");
    // const currentDateTimeCST = moment().tz("America/Chicago");
    console.log(amShift,pmShift);

    let shift = 'NA';
    // console.log(currentTimeInMint , selectedBoardZone)
    if(amShift && amShift !== null){
      amShift = moment.tz(amShift, 'HH:mm:ss', timeZone).subtract(getHoursDiff(), 'h');
      let amEndCST = (selectedBoardZone.am_shift_buffer_time !== null)? 
      amShift.clone().add(selectedBoardZone.am_shift_buffer_time, 'hours'):
      amShift.clone();
      let amStartCST = moment(amShift.format("YYYY-MM-DD HH:mm:ss"))
      const minDate = moment(amStartCST).format('DD')
      const maxDate = moment(amEndCST).format('DD')

      let now = moment(currentDateTimeCST.format("YYYY-MM-DD HH:mm:ss")); //todays date
      // console.log("pmStartCSTbefore: ", amStartCST.format("YYYY-MM-DD HH:mm:ss"));
      let end = amStartCST.format("YYYY-MM-DD HH:mm:ss"); // another date
      let duration = moment.duration(now.diff(end));
      let mint = duration.asMinutes();
      // console.log("DIFF: ", mint);
  
      if(minDate !== maxDate && mint <= 0){
        amEndCST = amEndCST.subtract(1, 'days');
        amStartCST= amStartCST.subtract(1, 'days');
      }
      console.log("AMCurrent: -- ", currentDateTimeCST.format("YYYY-MM-DD HH:mm:ss"))
      console.log(currentDateTimeCST.format("YYYY-MM-DD HH:mm:ss"), "isBtwn", amStartCST.format("YYYY-MM-DD HH:mm:ss"), amEndCST.format("YYYY-MM-DD HH:mm:ss"))
      if (moment(currentDateTimeCST.format("YYYY-MM-DD HH:mm:ss")).isBetween(amStartCST.format("YYYY-MM-DD HH:mm:ss"), amEndCST.format("YYYY-MM-DD HH:mm:ss"))) {
          console.log('currentDateTimeCST is between amShift');
          shift = 'AM'
      }  
    }
    if(pmShift && pmShift !==null){
      pmShift = moment.tz(pmShift, 'HH:mm:ss',timeZone).subtract(getHoursDiff(), 'h');
      let pmEndCST = (selectedBoardZone.pm_shift_buffer_time !== null)? 
        pmShift.clone().add(selectedBoardZone.pm_shift_buffer_time, 'hours'):
        pmShift.clone();
      let pmStartCST = moment.tz(pmShift.format("YYYY-MM-DD HH:mm:ss"), format, timeZone)

      const minDate = moment.tz(pmStartCST, 'DD', timeZone)
      const maxDate = moment.tz(pmEndCST, 'DD', timeZone)

      let now = moment(currentDateTimeCST.format("YYYY-MM-DD HH:mm:ss")); //todays date
      console.log("pmStartCSTbefore: ", pmStartCST.format("YYYY-MM-DD HH:mm:ss"));
      let end = pmStartCST.format("YYYY-MM-DD HH:mm:ss"); // another date
      let duration = moment.duration(now.diff(end));
      let mint = duration.asMinutes();
      // console.log("DIFF: ", mint);
  
      if(minDate !== maxDate && mint <= 0){
        pmEndCST = pmEndCST.subtract(1, 'days');
        pmStartCST= pmStartCST.subtract(1, 'days');
      }
      console.log(currentDateTimeCST.format("YYYY-MM-DD HH:mm:ss"), "isBtwnPM?", pmStartCST.format("YYYY-MM-DD HH:mm:ss"), pmEndCST.format("YYYY-MM-DD HH:mm:ss"))

      if (moment(currentDateTimeCST.format("YYYY-MM-DD HH:mm:ss")).isBetween(pmStartCST.format("YYYY-MM-DD HH:mm:ss"), pmEndCST.format("YYYY-MM-DD HH:mm:ss"))) {
          console.log('currentDateTimeCST is between pmShift');
          shift = shift ==='AM'? 'All': 'PM'
      }  
    }
    console.log("old",getActiveShiftOld(selectedBoardZone))
    return shift
  }
  return 'All'
  }catch(e){
    console.log(e)
    return 'NA'
  }
}