import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Suspense } from "react";
import { Spin } from "antd";
import { Button, Result } from 'antd';

const PrivateRoute = ({ Component, path, authorized }) => {
  const isUserLogin = useSelector((state) => state.loginReducer.isUserLogin);
  const userData = useSelector((state) => state.loginReducer.userData);
  const userType = useSelector((state) => state.loginReducer.userType);
  let navigate = useNavigate();

  if (isUserLogin && userData) {
    if (
      (path.includes("zones") &&
      userData.role_in_company_name !== "Admin")
      || (authorized!==undefined && !authorized)
    ) {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={() => navigate(
              userData !== null && userType.tenant.includes(userData.company_type)
              ?"/dashboard"
              :"/dispatch")}>
              Back Home
            </Button>
          }
        />
      );
    }
    return (
      <Suspense
        fallback={
          <div className={"lazy-loader-spin"}>
            <Spin size="large" />
          </div>
        }
      >
        <Component />
      </Suspense>
    );
  }
  return <Navigate to="/login" />;
};

export default PrivateRoute;
