import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { lazy } from "react";
import { useSelector } from "react-redux";
import Login from "../Pages/Login/Login";
import PrivateRoute from "./PrivateRoute";
import HappeningNow from "../Pages/HappeningNow/happeningNow";
const Zones = lazy(() => import("../Pages/Zones/Zones"));
const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"));
const Board = lazy(() => import("../Pages/Board/Boards"));
const DriverBoard = lazy(() => import("../Pages/Driver/DriverBoard"));
const Profile = lazy(() => import("../Pages/Profile/Profile"));
const Main = lazy(() => import("../Components/Main"));

const RouterConfig = () => {
  const userData = useSelector((state) => state.loginReducer.userData);
  const userType = useSelector((state) => state.loginReducer.userType);
  return (
    <Router>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route
          path="/happeningNow/:zone?/:carrier?/:tenant?"
          element={<HappeningNow />}
        />
        <Route path="/login/:userToken" element={<Login />} />
        <Route path="/" element={<PrivateRoute Component={Main} path={"/"} />}>
          <Route
            path="/"
            element={
              <Navigate
                replace
                to={
                  userData !== null &&
                  userType.tenant.includes(userData?.company_type)
                    ? "/dashboard"
                    : "/dispatch"
                }
              />
            }
          />
          <Route
            path="dispatch"
            element={
              <PrivateRoute
                Component={Board}
                path={"dispatch"}
                authorized={true}
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <PrivateRoute
                Component={Dashboard}
                path={"dashboard"}
                authorized={
                  userData !== null &&
                  userType.tenant.includes(userData?.company_type)
                }
              />
            }
          />
          <Route
            path="drivers"
            element={
              <PrivateRoute
                Component={DriverBoard}
                path={"drivers"}
                authorized={true}
              />
            }
          />
          <Route
            path="zones"
            element={
              <PrivateRoute
                Component={Zones}
                path={"zones"}
                authorized={
                  userData !== null &&
                  userType.tenant.includes(userData?.company_type)
                }
              />
            }
          />
          <Route
            path="profile"
            element={
              <PrivateRoute
                Component={Profile}
                path={"profile"}
                authorized={true}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};
export default RouterConfig;
