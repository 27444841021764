import { useState, useEffect } from "react";
import { useTimer } from "use-timer";
import { calQueueTime } from "../utils/date";
const QueueTime = ({ user }) => {
  const { time, start } = useTimer();

  const [queueTime, setQueueTime] = useState("");
  useEffect(() => {
    start();
    setQueueTime(calQueueTime(user));
  }, [time]);
  return queueTime;
};
export default QueueTime;
