import { DataStore } from "aws-amplify/datastore";
import { CatHerd } from "../models";
import { updateState } from "../store/actions/main_actions";
import { notification } from "antd";
import store from "../store/store.js";
import { BulkprogressNotification } from "../Components/Notifications/NotificationView";
import { getZonesByTenant } from "../services/apiCalls.js";

export const makeZoneBaseTreeAggregation = async (drivers) => {
  try {
    let tenantId = store.getState().mainReducer.selectedCustomer;
    let userData = store.getState()?.loginReducer?.userData;
    let userType = store.getState()?.loginReducer?.userType;
    let zones = [];
    if (userType.carrier.includes(userData.company_type)) {
      store.dispatch(updateState({ zoneWiseShiftSideListLoading: true }));
      zones = await getZonesByTenant(
        userData?.company_id,
        tenantId,
        [],
        "all",
        false
      );
      store.dispatch(updateState({ zoneWiseShiftSideListLoading: false }));
    } else
      zones = await DataStore.query(CatHerd, (zone) =>
        zone.and((zone) => [zone.id.beginsWith("ZONE"), zone.cat.eq("ZONE")])
      );

    let tree = {};
    tree["ZONE#"] = {
      name: "No Zone",
      id: "None",
      activeStatus: true,
      shifts: {
        All: 0,
        AM: 0,
        PM: 0,
        NA: 0,
      },
    };
    zones.forEach(function (zone) {
      // if(zoneWiseShiftSideList[zone.id]){
      tree[zone.id] = {
        name: zone.z_name,
        id: zone.id,
        activeStatus: zone.status,
        shifts: {
          All: 0,
          AM: 0,
          PM: 0,
        },
      };
      // }
    });

    drivers.forEach(function (driver) {
      if (!tree.hasOwnProperty(driver.gsi1sk)) {
        tree[driver.gsi1sk] = {
          name: driver.z_name,
          id: driver.gsi1sk,
          // activeStatus: driver.status,
          shifts: {
            All: 0,
            AM: 0,
            PM: 0,
          },
        };
      }
      if (
        tree.hasOwnProperty(driver.gsi1sk) &&
        tree[driver.gsi1sk]["shifts"][driver.shift] !== undefined
      ) {
        tree[driver.gsi1sk]["shifts"][driver.shift] = tree[driver.gsi1sk][
          "shifts"
        ][driver.shift]
          ? tree[driver.gsi1sk]["shifts"][driver.shift] + 1
          : 1;
        tree[driver.gsi1sk]["shifts"]["All"] = tree[driver.gsi1sk]["shifts"][
          "All"
        ]
          ? tree[driver.gsi1sk]["shifts"]["All"] + 1
          : 1;
      }
    });
    store.dispatch(
      updateState({
        zoneWiseShiftSideList: { ...tree },
      })
    );
  } catch (e) {
    console.log(e);
  }
};

export const updateZoneBaseAggregation = async (drivers) => {
  let tree = store.getState().mainReducer.zoneWiseShiftSideList;
  let tabDrivers = store.getState().mainReducer.driverTabDrivers;
  let user = store.getState().loginReducer.userData;
  let isChangedOccured = false;
  Object.keys(drivers).map((key) => {
    let newdriver = drivers[key];
    if (
      newdriver.triggerType === "UNASSIGNED" ||
      newdriver.triggerType === "NA" ||
      newdriver.dd_tenant === "TENANT#" ||
      newdriver._deleted === true
    ) {
      if (tree[newdriver.gsi1sk] && tabDrivers[`${newdriver.id}`]) {
        let oldDriver = tabDrivers[`${newdriver.id}`];
        tree[oldDriver.gsi1sk]["shifts"]["All"] =
          tree[oldDriver.gsi1sk]["shifts"]["All"] > 0
            ? tree[oldDriver.gsi1sk]["shifts"]["All"] - 1
            : 0;
        tree[oldDriver.gsi1sk]["shifts"][oldDriver.shift] =
          tree[oldDriver.gsi1sk]["shifts"][oldDriver.shift] > 0
            ? tree[oldDriver.gsi1sk]["shifts"][oldDriver.shift] - 1
            : 0;
      }
      delete tabDrivers[`${newdriver.id}`];
      isChangedOccured = true;
    } else {
      let oldDriver = tabDrivers[key];
      if (oldDriver === undefined) {
        if (
          newdriver.dd_tenant === `TENANT#${user?.company_id}` &&
          (newdriver.triggerType === null ||
            newdriver.triggerType === "ASSIGNED") &&
          tree[newdriver.gsi1sk]
        ) {
          // console.log(key ,tabDrivers[key], newdriver.dd_tenant)
          tree[newdriver.gsi1sk]["shifts"]["All"] =
            tree[newdriver.gsi1sk]["shifts"]["All"] + 1;
          tree[newdriver.gsi1sk]["shifts"][newdriver.shift] =
            tree[newdriver.gsi1sk]["shifts"][newdriver.shift] + 1;
          tabDrivers[newdriver.id] = newdriver;
          isChangedOccured = true;
        }
      } else {
        if (parseInt(oldDriver._version) < parseInt(newdriver._version)) {
          tabDrivers[newdriver.id] = newdriver;
          isChangedOccured = true;
        }
        if (
          newdriver.gsi1sk !== oldDriver.gsi1sk ||
          newdriver.shift !== oldDriver.shift
        ) {
          //zone or shift  changed
          tree[oldDriver.gsi1sk]["shifts"]["All"] =
            tree[oldDriver.gsi1sk]["shifts"]["All"] > 0
              ? tree[oldDriver.gsi1sk]["shifts"]["All"] - 1
              : 0;
          tree[oldDriver.gsi1sk]["shifts"][oldDriver.shift] =
            tree[oldDriver.gsi1sk]["shifts"][oldDriver.shift] > 0
              ? tree[oldDriver.gsi1sk]["shifts"][oldDriver.shift] - 1
              : 0;
          tree[newdriver.gsi1sk]["shifts"]["All"] =
            tree[newdriver.gsi1sk]["shifts"]["All"] + 1;
          tree[newdriver.gsi1sk]["shifts"][newdriver.shift] =
            tree[newdriver.gsi1sk]["shifts"][newdriver.shift] + 1;
          isChangedOccured = true;
        }
      }
    }
    return null;
  });
  if (isChangedOccured) {
    // console.log(isChangedOccured,'---',tree)
    store.dispatch(
      updateState({
        zoneWiseShiftSideList: { ...tree },
        driverTabDrivers: { ...tabDrivers },
      })
    );
  }
};
export const addOrRemoveZoneAggregation = async (actionType, zone) => {
  let tree = store.getState().mainReducer.zoneWiseShiftSideList;
  switch (actionType) {
    case "INSERT":
      store.dispatch(
        updateState({
          zoneWiseShiftSideList: {
            ...tree,
            [zone.id]: {
              name: zone.z_name,
              id: zone.id,
              activeStatus: zone.status,
              shifts: {
                All: 0,
                AM: 0,
                PM: 0,
              },
            },
          },
        })
      );
      break;
    case "DELETE":
      delete tree[zone.id];
      store.dispatch(
        updateState({
          zoneWiseShiftSideList: { ...tree },
        })
      );
      break;
    default:
      break;
  }
};

export const resetFilters = (panel) => {
  let driverFilters = store.getState().mainReducer.driverFilters;
  switch (panel) {
    case "Load":
      store.dispatch(
        updateState({
          loadFilters: {
            job: "All",
            groupby: "None",
            sortby: "Order",
          },
        })
      );
      break;
    case "boardTab":
      store.dispatch(
        updateState({
          driverFilters: {
            ...driverFilters,
            [panel]: {
              shift: "All",
              carrier: "All",
              sortby: "None",
              view_type: "Queue",
              sortType: "A",
            },
          },
        })
      );
      break;
    case "ZoneCustomer":
      store.dispatch(
        updateState({
          nameFilter: "All",
        })
      );
      break;
    case "driversTab":
      store.dispatch(
        updateState({
          driverFilters: {
            ...driverFilters,
            [panel]: {
              checkIn: "All",
              zone: "All",
              carrier: "All",
              customer: "All",
              shift: "All",
            },
          },
        })
      );
      store.dispatch(
        updateState({
          nameFilter: "All",
        })
      );
      break;
    default:
      break;
  }
};
export const updateObserIds = (drivers) => {
  let ids = store.getState().mainReducer.observeIds;
  let observeIdsCount = store.getState().mainReducer.observeIdsCount;
  // console.log("drivers", drivers, ids)
  if (ids.length > 0) {
    Object.keys(drivers).map((key) => {
      let newdriver = drivers[key];
      ids = ids.filter((id) => id !== newdriver.id);
      return null;
    });
    // if(ids.length === 0){
    store.dispatch(
      updateState({
        observeIds: [...ids],
      })
    );
    notification.open({
      key: "bulk-notification",
      description: (
        <BulkprogressNotification
          total={observeIdsCount}
          remaining={ids.length}
        />
      ),
      placement: "bottomLeft",
      duration: ((observeIdsCount - ids.length) / observeIdsCount) * 10,
      onClose: () => {
        if (ids.length > 0) {
          notification.open({
            key: "bulk-notification",
            description: (
              <BulkprogressNotification total={observeIdsCount} remaining={0} />
            ),
            placement: "bottomLeft",
            duration: 10,
          });
          store.dispatch(
            updateState({
              observeIdsCount: 0,
              observeIds: [],
            })
          );
        }
      },
    });
    if (ids.length === 0) {
      store.dispatch(
        updateState({
          observeIdsCount: 0,
        })
      );
    }
  }
};

export const sortList = (list, byKey) => {
  return list.sort((a, b) => a[byKey]?.localeCompare(b[byKey]));
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getLoadStatusName = (status) => {
  switch (status) {
    case "1":
      return "Assigned";
    case "2":
      return "Accepted";
    case "4":
      return "At Terminal";
    case "5":
      return "In-Transit";
    case "6":
      return "At Destination";
    default:
      return "On load with different company";
  }
};

export const editingModeWarning = () => {
  notification.warning({
    description: "Notes changes are still pending, Save them.",
    placement: "bottomLeft",
  });
};
export const updateBoardDriverList = (driver) => {
  try {
    let boardDrivers = store.getState().mainReducer.drivers;
    let selectedBoardZone = store.getState().mainReducer.selectedBoardZone;
    let { boardTab } = store.getState().mainReducer.driverFilters;
    let driverIndex = boardDrivers.findIndex((o) => o.id === driver.id);
    let boardDriver = boardDrivers[driverIndex];
    // for unassigned drivers list (No ZONE)
    if (selectedBoardZone === null && driver.gsi1sk === "ZONE#") {
      if (
        driverIndex !== -1 &&
        (driver._deleted === true ||
          driver.gsi1sk !== "ZONE#" ||
          driver.dd_tenant === "TENANT#" ||
          driver.triggerType !== "ASSIGNED")
      ) {
        boardDrivers.splice(driverIndex, 1);
      } else if (driver.gsi1sk === "ZONE#" && driverIndex !== -1) {
        boardDrivers[driverIndex] = driver;
      } else if (driverIndex === -1 && driver.gsi1sk === "ZONE#") {
        boardDrivers.push(driver);
      }
    } else {
      // for assigned drivers list (ZONE)
      if (driverIndex !== -1) {
        //driver already exist
        if (
          driver._deleted === true ||
          driver.check_in === "0" ||
          (driver.is_assigned === false && boardTab.view_type === "Assigned") ||
          (driver.is_assigned === true && boardTab.view_type === "Queue") ||
          driver.dd_tenant === "TENANT#" ||
          driver.triggerType !== "ASSIGNED"
        ) {
          //view type changed of driver or checkout or deleted
          boardDrivers.splice(driverIndex, 1);
        } else if (driver.gsi1sk !== boardDriver.gsi1sk) {
          //zone unassigned
          if (driver.gsi1sk === "ZONE#") {
            boardDrivers.splice(driverIndex, 1);
          } else {
            // zone changed
            boardDrivers[driverIndex] = driver;
          }
        } else {
          boardDrivers[driverIndex] = driver;
        }
      } else {
        //if driver not exist
        if (
          driver.gsi1sk === selectedBoardZone.id &&
          driver.check_in === "1" &&
          ((driver.is_assigned === false && boardTab.view_type === "Queue") ||
            (driver.is_assigned === true && boardTab.view_type === "Assigned"))
        ) {
          //check viewtype and add driver
          boardDrivers.splice(driver.line_up_order, 0, driver);
        }
      }
    }
    store.dispatch(
      updateState({ drivers: [...boardDrivers], boardLoading: false })
    );
  } catch (e) {
    console.log(e);
  }
};
