import { useDragLayer } from "react-dnd";
import { useSelector } from "react-redux";
import { DragPreview } from "./DragPreview";

const snapToGrid = (x, y) => {
  const snappedX = Math.round(x / 32) * 32;
  const snappedY = Math.round(y / 32) * 32;
  return [snappedX, snappedY];
};

export const CustomDragLayer = (props) => {
  const multiSelectDriver = useSelector(
    (state) => state.mainReducer.multiSelectDriver
  );
  let layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "20",
  };
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
    isOverTarget,
    initialClient,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    initialClient: monitor.getInitialClientOffset(),
    isOverTarget: (() => {
      if (monitor.getTargetIds()) {
        const targetIds = monitor.getTargetIds() || [];
        if (targetIds.length > 0) {
          for (let i = targetIds.length - 1; i >= 0; i--) {
            return monitor.isOverTarget(targetIds[i]);
          }
        }
        return false;
      }
      return false;
    })(),
  }));

  function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
    if (!initialOffset || !currentOffset) {
      return {
        display: "none",
      };
    }
    let { x, y } = currentOffset;
    y += 50;
    if (isSnapToGrid) {
      x -= initialOffset.x;
      y -= initialOffset.y;
      [x, y] = snapToGrid(x, y);
      x += initialOffset.x;
      y += initialOffset.y;
    }
    if (itemType === "driver" && multiSelectDriver.length > 1) {
      x += initialClient.x - initialOffset.x;
      y += initialClient.y - initialOffset.y - 50;
    }
    if (isOverTarget) {
      if (
        itemType === "user" ||
        (itemType === "driver" && multiSelectDriver.length <= 1)
      ) {
        x += initialClient.x - initialOffset.x;
        y += initialClient.y - initialOffset.y - 50;
      }
    }
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }
  function renderItem() {
    return (
      <DragPreview
        itemType={itemType}
        item={item}
        isOverTarget={isOverTarget}
        previewType={itemType}
        isDragging={isDragging}
      />
    );
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, false)}>
        {renderItem()}
      </div>
    </div>
  );
};
