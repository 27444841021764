import axios from 'axios';
import store from '../store/store'
// const UNAUTHORIZED = 401;
// TODO will handle this at central place
// Add a request interceptor
axios.interceptors.request.use((config) => {
  if(config.url.includes(process.env.REACT_APP_PD_BASE_URL)){
    const token = store.getState().loginReducer.userData?.token;
    if ( token != null ) {
      config.headers.token = `${token}`;
    }
  }
  return config;
}, (error) => {
  // Do something with request error
  return Promise.reject(error);
});

export const apiRequestAxio = (method,uri,body,header) => {
  const httpMethod = method.toUpperCase();
  const requestOptions = {
    url : `${process.env.REACT_APP_PD_BASE_URL}${uri}`,
    method :   method.toUpperCase(),
    headers : (uri.indexOf('login') !== -1)
              ?{
                "Content-Type": "application/json",
                "X-API-KEY":process.env.REACT_APP_X_API_KEY,
                "pd-utype": process.env.REACT_APP_PD_UTYPE,
                "pch-app-token":process.env.REACT_APP_PCH_APP_TOKEN,
                "type":"2",
              }
              :(header && header.token) //for autologin
              ? header
              :{ 
                "Content-Type": "application/json",
                "X-API-KEY":process.env.REACT_APP_X_API_KEY
              }
  };
  if ((httpMethod === 'POST' || httpMethod === 'PUT' || httpMethod === 'PATCH' || httpMethod === 'OPTIONS')) 
      if(header && header["Content-Type"] === 'multipart/form-data'){
        requestOptions.data = body;
      }else
        requestOptions.data = JSON.stringify(body);
  return axios(requestOptions);
};
