import { useDrag, useDrop } from "react-dnd";
import { List, Collapse, Row, Col, Divider, notification } from "antd";
import { getEmptyImage } from "react-dnd-html5-backend";
import * as _ from "underscore";
import React, { useState, useEffect } from "react";

import { apiRequestAxio } from "../../services/axioCall";
import HaulingCostModal from "../../Components/Modals/HaulingCostModal";
import { updateCatherdTuple } from "../../services/appSyncCalls";
import { useSelector } from "react-redux";
const style = {
  cursor: "move",
};
const { Panel } = Collapse;

export const LoadItem = ({ index, load, toggleLoadsdDetail, previewList }) => {
  const [isOpen, setIsOpen] = useState(toggleLoadsdDetail);
  const [openModal, setOpenModal] = useState(false);
  const [body, setBody] = useState(null);
  const [cost, setCost] = useState([]);
  const userData = useSelector((state) => state.loginReducer.userData);

  useEffect(() => {
    if (toggleLoadsdDetail) setIsOpen(true);
    else setIsOpen(false);
  }, [toggleLoadsdDetail]);
  // const assignLoadtoDriver = async (load, driver) => {
  //   try {
  //     if((!driver.is_assigned && driver.driver_flagged !== true)){
  //       let body = {
  //         load_id: parseInt(load.id.replace("LOAD#", "")),
  //         driver_id: parseInt(driver.driver_id),
  //       };
  //       let response = await apiRequestAxio(
  //         "POST",
  //         "pch/pch_integrations/assign_load_driver",
  //         body
  //       );
  //       if(response?.data?.data){
  //         response.data.message === "Data Added Successfully"
  //               ? notification.open({
  //                   description: (
  //                     <p>
  //                       <b>{driver.driver_name}</b> assigned to{" "}
  //                       <b>Load No. {load.load_no}</b>
  //                     </p>
  //                   ),
  //                   placement: "bottomLeft",

  //                 }):
  //                 notification.open({
  //                   description: response?.data?.message,
  //                   placement: "bottomLeft",

  //                 })
  //       }else{
  //         notification.open({
  //           description: response?.data?.message,
  //           placement: "bottomLeft",

  //         })
  //       }
  //   }else{
  //     let msg = 'Already another load is assigned to this driver'
  //     if(driver.driver_flagged === true){
  //       msg ='Driver cannot be dispatched until flag is removed'
  //     }
  //     notification.open({
  //       description: msg,
  //       placement: "bottomLeft",

  //     });
  //   }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   return false;
  // };
  const assignLoadtoDriver = async (body) => {
    try {
      setCost([]);
      let { load, driver, equipment_basin_id } = body;
      if (
        !driver.is_assigned &&
        driver.flag_type === null &&
        driver.on_duty === "1" &&
        driver.driver_linked_status === "1" &&
        load &&
        driver &&
        userData.company_type !== "2"
      ) {
        body = equipment_basin_id
          ? {
              load_id: parseInt(load.id.replace("LOAD#", "")),
              driver_id: parseInt(driver.driver_id),
              equipment_basin_id,
            }
          : {
              load_id: parseInt(load.id.replace("LOAD#", "")),
              driver_id: parseInt(driver.driver_id),
            };
        setBody({ load, driver });
        let response = await apiRequestAxio(
          "POST",
          "pch/pch_integrations/assign_load_driver",
          body
        );
        let restType = response?.data?.type;
        switch (restType) {
          case "LIST": //In Case List Return
            setCost(response?.data?.data);
            setOpenModal(true);
            return false;
          case "ASSIGNED": //IN Case Success
            let tupleList = [];
            tupleList.push(
              updateCatherdTuple({
                id: load.id,
                cat: load.cat,
                _version: load._version,
                is_assigned: true,
              })
            );
            tupleList.push(
              updateCatherdTuple({
                id: driver.id,
                cat: driver.cat,
                _version: driver._version,
                is_assigned: true,
              })
            );
            await Promise.all(tupleList);
            response.data.message === "Data Added Successfully"
              ? notification.open({
                  description: (
                    <p>
                      <b>{driver.driver_name}</b> assigned to{" "}
                      <b>Load No. {load.load_no}</b>
                    </p>
                  ),
                  placement: "bottomLeft",
                })
              : notification.open({
                  description: response?.data?.message,
                  placement: "bottomLeft",
                });
            return true;
          case "UNASSIGNED": //In Case Not Succes
          default:
            notification.open({
              description: response?.data?.message,
              placement: "bottomLeft",
            });
            return false;
        }
      } else {
        let msg = "Already another load is assigned to this driver";
        if (driver.flag_type !== null) {
          msg = "Driver cannot be dispatched until flag is removed";
        } else if (driver.on_duty !== "1") {
          msg = "Driver is unavailable, and cannot be dispatched";
        } else if (driver.driver_linked_status !== "1") {
          msg = "Driver is inactive, and cannot be dispatched";
        } else if (userData.company_type === "2") {
          msg = "cannot assign loads in carrier view";
        }
        notification.open({
          description: msg,
          placement: "bottomLeft",
        });
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  function attachRef(el) {
    drag(el);
    drop(el);
  }
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "user",
      drop: (driver) => {
        // assignLoadtoDriver(load, driver); v8

        assignLoadtoDriver({ load, driver });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [load]
  );
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "load",
      item: load,
      end: (load, monitor) => {
        const driver = monitor.getDropResult();
        if (load && driver) {
          // assignLoadtoDriver(load, driver); v8
          assignLoadtoDriver({ load, driver });
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [load]
  );

  preview(getEmptyImage(), { captureDraggingState: true });
  const opacity = isDragging ? 0.3 : 1;
  let border1 = "";
  let border = isDragging ? "2px dashed #d2d4db" : "";
  const isActive = canDrop && isOver;
  let backgroundColor = "white";
  let borderRadius = "";
  let marginLeft = "";
  if (isActive) {
    backgroundColor = "rgb(230, 235, 238)";
    border1 = "1px solid #093254";
    borderRadius = "4px";
    marginLeft = "8px";
  } else if (canDrop) {
    border1 = "1px solid #093254";
    borderRadius = "4px";
    marginLeft = "8px";
  }

  const LoadAvatar = ({ load, loadIndex }) => (
    <Collapse
      accordion
      bordered={false}
      ghost
      className="load-panel"
      expandIconPosition="end"
      defaultActiveKey={isOpen ? [loadIndex] : []}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Panel
        showArrow={!previewList}
        key={loadIndex}
        header={
          <div>
            <div style={{ textAlign: "left" }}>
              <h4>
                <b>{load.job_name || ""}</b>
              </h4>
            </div>
            <div>
              <Row>
                <Col span={12}>
                  <p className="carrier-fedex mb-4 ">
                    Load No: <span>{load.load_no || ""}</span>{" "}
                  </p>{" "}
                </Col>
                <Col span={12}>
                  <p className="carrier-fedex mb-4 w-182 web-content">
                    Product:<span> {load.product_name || ""}</span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <p className="carrier-fedex mb-0 web-content">
                    Terminal: <span>{load.terminal_name || ""}</span>
                  </p>
                </Col>
                <Col span={12}>
                  <p className="carrier-fedex mb-0 web-content ">
                    Operator: <span>{load.operator_name || ""}</span>{" "}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        }
      >
        <>
          <Divider />
          <Row className="d-grid">
            <Col span={12} className="district-details">
              <span className="driver-detail-heading"> Terminal </span>
              <br />
              <p> {load.terminal_name || ""} </p>
            </Col>
            <Col span={12}>
              <span className="driver-detail-heading"> Product</span>
              <br />
              <p>{load.product_name || ""}</p>
            </Col>
          </Row>
          <Row className="d-grid">
            <Col span={12} className="district-details">
              <span className="driver-detail-heading"> Stage</span>
              <br />
              <p> {load.stage || ""} </p>
            </Col>
            <Col span={12}>
              <span className="driver-detail-heading"> Est. Hauling</span>
              <br />
              <p> </p>
            </Col>
          </Row>
        </>
      </Panel>
    </Collapse>
  );
  const listItem = (itemClassName) => (
    <div
      ref={attachRef}
      role="load"
      className={itemClassName}
      style={{
        ...style,
        opacity,
        backgroundColor,
        border1,
        border,
        borderRadius,
        marginLeft,
      }}
      data-testid={`${index}`}
    >
      <List.Item style={{ padding: "0px" }}>
        <List.Item.Meta title={<LoadAvatar load={load} loadIndex={index} />} />
      </List.Item>
    </div>
  );
  return (
    <>
      {previewList ? (
        <Row>
          <Col xs={2} sm={4} md={6} lg={9} xl={9}>
            {listItem("rectangle-copy-1")}
          </Col>
        </Row>
      ) : (
        <>
          {listItem("card-list")}
          <HaulingCostModal
            openModal={openModal && cost.length > 0}
            setOpenModal={setOpenModal}
            body={body}
            assignLoadtoDriver={assignLoadtoDriver}
            costList={cost}
            setCostList={setCost}
          />
        </>
      )}
    </>
  );
};
