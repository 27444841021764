import { GetProgressBar } from "../../Components/Zone";
import { v4 as uuidv4 } from "uuid";
import ZoneTargetStats from "../../Components/ZoneTargetStats";
import { Divider, Collapse, Row, Col } from "antd";

const { Panel } = Collapse;

const ZoneTargetPanel = ({zoneInfo}) =>{
    return(
        <Collapse
              collapsible="disabled"
                accordion
                bordered={false}
                ghost
                className="zone-panel"
                defaultActiveKey={"zone"}
                expandIconPosition="end"
              >
                <Panel
                  key={"zone"}
                  showArrow={false}
                  header={
                    <div style={{ textAlign: "left" }}>
                      <h5>
                      <ZoneTargetStats withColor={false} stats={{checkIn:
                        ((zoneInfo?.am_checkIn ?? 0) + (zoneInfo?.am_checkIn ?? 0)), 
                        actual:((zoneInfo?.am_actual ?? 0) + (zoneInfo?.pm_actual ?? 0)), 
                        target:((zoneInfo?.am_target ?? 0) + (zoneInfo?.pm_target ?? 0))}}/>
                      </h5>
                      <Row gutter={[24, 20]} className="d-grid ">
                        <Col span={24} className={"pt-2"}>
                          <div className="zone-details">
                            <small className={"am"}>AM</small>
                            <ZoneTargetStats withColor={true} 
                              stats={{checkIn:zoneInfo?.am_checkIn ?? 0, 
                              actual: zoneInfo?.am_actual ?? 0, 
                              target: zoneInfo?.am_target ?? 0}}/>
                          </div>
                          <GetProgressBar
                            barNum={[zoneInfo?.am_checkIn ?? 0, zoneInfo?.am_actual ?? 0, zoneInfo?.am_target ?? 0 ]}
                            />
                        </Col>
                        <Col span={24}>
                          <div className="zone-details">
                            <small
                              className={"pm" }
                            >
                              PM
                            </small>
                            <ZoneTargetStats withColor={true} 
                              stats={{checkIn:zoneInfo?.pm_checkIn ?? 0, 
                                actual: zoneInfo?.pm_actual ?? 0, 
                                target: zoneInfo?.pm_target ?? 0}}/>
                          </div>
                          <GetProgressBar
                            barNum={[zoneInfo?.pm_checkIn ?? 0, zoneInfo?.pm_actual ?? 0, zoneInfo?.pm_target ?? 0 ]}
                          />
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <>
                    <Divider />
                    {zoneInfo?.carriers?.length > 0 ? (
                      <div>
                        {zoneInfo?.carriers.map((carrier) => {
                          return (
                            <div className="zone-carrier-list" key={uuidv4()}>
                              <h4 className="driver-name">
                                {carrier.name}
                              </h4>
                              {Object.keys(carrier).map((key) =>{
                                return (
                                  key === 'AM' || key === 'PM'?
                                  <Row
                                    className={ "d-grid"}
                                  >
                                    <Col span={17}>
                                      <div className="zone-details">
                                        <small className={key === 'AM'? "am": 'pm'}> {key} </small>
                                        <ZoneTargetStats withColor={true} 
                                        stats={{checkIn: carrier[key]?.checkIn ?? 0, 
                                        actual: carrier[key]?.actual ?? 0, 
                                        target: carrier[key]?.target ?? 0}}/>
                                      </div>
                                    </Col>
                                    <Col span={7}>
                                      <GetProgressBar
                                        barNum={[carrier[key]?.checkIn ?? 0, carrier[key]?.actual ?? 0, carrier[key]?.target ?? 0]}
                                      />
                                    </Col>
                                  </Row>
                                  : null
                                )
                              })}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="empty-data">
                        <h2>No Carrier Linked</h2>
                        <p>There are no carrier for this zone</p>
                      </div>
                    )}
                  </>
                
                </Panel>
        </Collapse>
    )
}
export default ZoneTargetPanel