/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://oh2os722cbfczjlayelwdfx2ce.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "aws_appsync_apiKey": "da2-7xixhyksu5dnrewaxv555xf5da",
    "aws_cloud_logic_custom": [
        {
            "name": "ddBackendApi",
            "endpoint": "https://vrk9otkxg8.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        },
        {
            "name": "dynamoElasticSyncApi",
            "endpoint": "https://qi4jok9kzb.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        },
        {
            "name": "zoneOps",
            "endpoint": "https://j2kpzlg3b8.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "pdEvents-live",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
