import {
  UPADTE_STATE,
  ADD_ZONE,
  REMOVE_ZONE,
  REMOVE_DRIVER,
} from "../actions/main_actions";

import { LOGOUT_USER } from "../actions/login_actions";
import { sortList } from "../../helpers/helperFunc";

const initialMainState = JSON.stringify({
  isDataStoreConfigured: false,
  // //-----------Board tab----------------------------
  showUnassigned: false,
  zones: [], //contain zone list
  zonesSideLoader: false,
  boardLoading: false, //for loading skeleton
  loadsLoading: true, //for loading skeleton
  customerLoading: true,
  drivers: [], //contain drivers listitems
  driverCarrierOption: [],
  driverFilters: {
    boardTab: {
      shift: "All",
      carrier: "All",
      sortby: "None",
      view_type: "Queue",
      sortType: "A",
    },
    driversTab: {
      checkIn: "All",
      zone: "None",
      carrier: "All",
      customer: "All",
      shift: "All",
    },
  },
  loadFilters: {
    job: "All",
    groupby: "None",
    sortby: "Order",
  },
  selectedBoardZone: null, //show active zone in board
  selectedZoneNotes: null,
  selectedZone: null, //show active zone in zones
  unassignedDriverCount: 0, // show all Unassigned Drivers

  //-----------search call----------------------------
  zonesAggregation: {},
  carrierTargetInfo: {},
  tenantTargetInfo: {},
  //-----------Zones tab state----------------------------
  loads: [], // contain loads listitem
  loadZoneFilter: null,
  usedZoneFilterTags: [],
  zoneSelectedJobsList: [], // for selected jobs tags
  zonesDropList: { basin: [], crew: [], district: [], jobs: [] }, //drop list of all filters

  //-----------zone insights-------------------------
  jobs: [],
  jobsLoading: true,

  //-------------------Driver tab state --------------------------------------
  multiSelectDriver: [],
  checkAll: false,
  customers: [],
  openMenu: null,
  driverListLoading: false,
  zoneWiseShiftSideListLoading: false,
  driverTabDrivers: {},
  carrierTabLoading: false,
  targetList: [],
  nameFilter: "All",
  modalDrivers: {},
  AssignModalLoading: true,
  zoneWiseShiftSideList: {},
  collapseZoneName: [],
  expandDrivers: {},
  selectedShift: null,

  //----------------Dashboard States----------------------
  selectedTruckingZone: { id: "All", z_name: "ALL" },
  truckingZoneInfo: {},
  truckingFilters: {
    shift: "All", //moment.tz("America/Chicago").format("A"),
  },
  //----------------for Bulk Sync Acknowledge----------------------
  observeIds: [],
  observeIdsCount: 0,

  //Menu Modal properties
  menuDriver: null,
  openMenuModal: false,
  menuModaltype: null,
  //notes
  editingMode: false,

  //-------------------Driver Activity Drawer --------------------------------------
  activities: [],
  drawerDriver: null,
  drawerOpen: false,
  drawerPlacement: "right",

  //-------------------Board Tab for Carrier View -------------------------
  selectedCustomer: null,
  zoneIds: null,
});
const MainState = JSON.parse(initialMainState);
let newState = {};
export default function mainReducer(state = MainState, action) {
  switch (action.type) {
    case UPADTE_STATE:
      newState = {};
      for (let key in action.payload) {
        newState = Object.assign(state, { [key]: action.payload[key] });
      }
      return { ...state, ...newState };
    case ADD_ZONE:
      let index = state.zones.findIndex((o) => o.id === action.payload.zone.id);
      let _zones = state.zones;
      if (index !== -1) {
        _zones[index] = action.payload.zone;
      } else _zones.push(action.payload.zone);

      const sortedZoneList = sortList(_zones, "z_name");
      state = { ...state, zones: [...sortedZoneList] };
      return state;
    // case UPDATE_ZONE:
    //   state = { ...state, zones: [...state.zones, action.payload.zone] };
    //   return state;
    case REMOVE_ZONE:
      let zones = state.zones.filter(
        (zone) => zone.id !== action.payload.data.id
      );
      state = { ...state, zones: zones };
      return state;
    case REMOVE_DRIVER:
      let drivers = state.drivers.filter(
        (driver) => driver.id !== action.payload.driver.id
      );
      state = { ...state, drivers: drivers };
      return state;
    case LOGOUT_USER:
      state = JSON.parse(initialMainState);
      return state;
    default:
      return state;
  }
}
