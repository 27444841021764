import mainReducer from "./mainReducer";
import loginReducer from "./loginReducer";
import { combineReducers } from "redux";
import { LOGOUT_USER } from "../actions/login_actions";

const appReducer = combineReducers({
  mainReducer,
  loginReducer,
});
export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
    localStorage.removeItem(`persist:auth`)
  }
  return appReducer(state, action);
};
