import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { List, Divider, Row, Col, Progress, Collapse,Tooltip } from "antd";
import { useDrop } from "react-dnd";
import { CatHerd } from "../models";
import { DataStore } from 'aws-amplify/datastore';
import { useDispatch, useSelector } from 'react-redux';
import { updateState } from "../store/actions/main_actions";
import ZoneTargetStats from "./ZoneTargetStats";
import { projectLiterals as literals } from "../utils/projectLiterals";
const { Panel } = Collapse;


export const GetProgressBar = ({barNum}) =>{
    let totalpercent = barNum[2] 
    // barNum.map(num=> totalpercent = totalpercent+num);
    return <Progress 
        success={{ percent: ( barNum[0]/ totalpercent) * 100, strokeColor: '#16AE63'}} //green
        percent={ (((barNum[1]-barNum[0])+barNum[0]) / totalpercent) * 100} //orange
        strokeColor='#F19F15' //orange
        strokeLinecap='square'
        trailColor='#F73B3B' //red
        showInfo={false} />
}
const ListContent = ({item, 
    collapseZones,
    toggleCollapse, 
    linkedCarrier, 
    itemType, 
    carrierTargetInfo,
    tenantTargetInfo}) => {
    const filters = useSelector((state) => state.mainReducer.driverFilters['driversTab']);
    const driverFilters = useSelector((state) => state.mainReducer.driverFilters);

    const updateFilters = (event, filterType, values) =>{
        event.stopPropagation();
        let zoneCustomerFilterKey = itemType === 'zone'?"zone":"customer"
        let zoneCustomerFilter = (itemType === 'zone')? filters.zone : filters.customer
        switch(filterType){
            case 'AM_CARRIER':
            case 'PM_CARRIER':
                dispatch(updateState({ 
                    driverFilters: {
                    ...driverFilters, 
                    driversTab: {...driverFilters['driversTab'], 
                    carrier: filters.shift === values.shift && filters.carrier === values.carrier? 'All': values.carrier,
                    shift: filters.shift === values.shift && filters.carrier === values.carrier && zoneCustomerFilter === 'All'  ?'All': values.shift,
                }}}))
            break;
            case 'PM_ZONE':
            case 'AM_ZONE':
                dispatch(updateState({ 
                    driverFilters: {
                    ...driverFilters, 
                    driversTab: {...driverFilters['driversTab'], 
                    carrier: (filters.shift === values.shift && filters.carrier !== 'All' && zoneCustomerFilter === values.id)? 'All':  filters.carrier,
                    shift: filters.shift === values.shift && (zoneCustomerFilter === values.id) ?'All': values.shift,
                    [zoneCustomerFilterKey]: filters.shift === values.shift && (zoneCustomerFilter === values.id)?'All':values.id
                }}}))
            break;
            default:
                break;
    
        }
    }
    const dispatch = useDispatch();
    const itemTargetInfo = 
        itemType === 'zone'
            ?carrierTargetInfo[item.id] ?? {}
            :item ?? {}
    
    const totalAmTargets = (itemType === 'zone')
        ?linkedCarrier.reduce((carrier,v) =>  carrier = carrier + v.am_target , 0 )
        :itemTargetInfo?.am_target ?? 0
    const totalPmTargets = (itemType === 'zone')
        ?linkedCarrier.reduce((carrier,v) =>  carrier = carrier + v.pm_target , 0 )
        :itemTargetInfo?.pm_target ?? 0
    const filter = (itemType === 'zone')? filters.zone : filters.customer
    return (
    <Collapse
        accordion
        bordered={false}
        ghost
        className="zone-panel"
        expandIconPosition="end"
        defaultActiveKey={collapseZones}
        onChange={() => toggleCollapse(item.id, false)}   
        >
       <Panel
        showArrow={(itemType === 'zone') ? true: false}
        key={item.id}
        header={
            <div style={{ "textAlign": "left" }}>
            <div className="d-flex driver-name">
               
                <Tooltip placement="top" title= {(item ? itemType === 'zone' && item?  
                    item.z_name ?? literals.TOOLTIP.UNKOWN :  item.name ?? literals.TOOLTIP.UNKOWN : literals.TOOLTIP.UNKOWN )}>
                <h2>
                    {(item ? itemType === 'zone' ?  
                    item.z_name ?? literals.TOOLTIP.UNKOWN :  item.name ?? literals.TOOLTIP.UNKOWN : literals.TOOLTIP.UNKOWN )}
                    
                </h2>
                </Tooltip>
             
               
                <small 
                    className={(filters.checkIn === 'Checked-In' && filter === item.id)?'checked-btn':'na'}
                    onClick={(event)=> {
                        event.stopPropagation();
                        dispatch(updateState({ 
                            driverFilters: {
                            ...driverFilters, 
                            driversTab: {...driverFilters['driversTab'], 
                            checkIn: filters.checkIn === 'Checked-In' && filter === item.id ? 'All': 'Checked-In',
                            [itemType === 'zone'?"zone":"customer"]: filters.checkIn === 'Checked-In' && filter === item.id? 'All': item.id,
                            carrier: 'All'
                        }}}))
                    }}
                >
                    Checked In
                </small>
                <small className={(filters.checkIn === 'Checked-out' && filter === item.id)? 'checked-btn':'na'}
                    onClick={(event)=> {
                        event.stopPropagation();
                        dispatch(
                            updateState({ 
                                driverFilters: {
                                ...driverFilters, 
                                driversTab: {...driverFilters['driversTab'], 
                                checkIn: filters.checkIn === 'Checked-out'? 'All': 'Checked-out',
                                [itemType === 'zone'?"zone":"customer"]: filters.checkIn === 'Checked-out' && filter === item.id? 'All': item.id,
                                carrier: 'All'
                            }}})
                        )
                    }}
                >
                    Checked Out
                </small>
            </div>
            <h5>
                <ZoneTargetStats withColor={false} 
                    stats={{checkIn: (itemTargetInfo?.am_checkIn ?? 0) + (itemTargetInfo?.pm_checkIn ?? 0), 
                    actual: (itemTargetInfo?.am_actual ?? 0) + (itemTargetInfo?.pm_actual ?? 0), 
                    target: (totalAmTargets ?? 0) + (totalPmTargets ?? 0)}}/>
            </h5>
                <Row gutter={[24, 20]} className="d-grid ">
                <Col span={24}
                className= {'pt-2'}
                >
                    <div className='zone-details'>
                        <small 
                        // className={'am'}
                        className= {filters.shift === 'AM' && (filters.zone === item.id || filters.customer === item.id)?'am target-div-highlight': 'am'}

                        onClick={(event)=> {
                            updateFilters(event, 'AM_ZONE', {id: item.id, shift: 'AM'})
                        }}
                        >AM</small> 
                        <ZoneTargetStats withColor={true} 
                            stats={{checkIn: itemTargetInfo?.am_checkIn ?? 0, 
                            actual: itemTargetInfo?.am_actual ?? 0, 
                            target: totalAmTargets}}/>
                    </div>
                    <GetProgressBar barNum={[
                        itemTargetInfo?.am_checkIn ?? 0,
                        itemTargetInfo?.am_actual ?? 0,
                        totalAmTargets
                    ]}/>
                </Col>
                <Col span={24}
                >
                    <div className='zone-details'>
                        <small 
                         className= {filters.shift === 'PM' && (filters.zone === item.id || filters.customer === item.id)?'pm target-div-highlight': 'pm'}
                         onClick={(event)=> {
                            updateFilters(event, 'PM_ZONE', {id: item.id, shift: 'PM'})
                        }}
                        >PM</small>
                        <ZoneTargetStats withColor={true} 
                                        stats={{checkIn: itemTargetInfo?.pm_checkIn ?? 0, 
                                        actual: itemTargetInfo?.pm_actual ?? 0, 
                                        target: totalPmTargets}}/>
                    </div>
                    <GetProgressBar barNum={[
                        itemTargetInfo?.pm_checkIn ?? 0,
                        itemTargetInfo?.pm_actual ?? 0,
                        totalPmTargets
                    ]}/>
                </Col>
                </Row>
             
            </div>
        }
        >
        {itemType === 'zone' ?  
        <>
            <Divider />
            {linkedCarrier.length > 0 ?
            <div> 
                {linkedCarrier.map(carrier => {
                    let carrierTarget = itemTargetInfo && itemTargetInfo.carriers && itemTargetInfo.carriers[carrier.carrier_id]
                        ?itemTargetInfo.carriers[carrier.carrier_id]
                        :{};
                    return (<div className="zone-carrier-list" key={uuidv4()}>
                      
                      <div className="d-flex driver-name">
                        <h2>{carrier.carrier_name}
                    
                        </h2>

                        <small 
                            className={(filters.checkIn === 'Checked-In' && filters.carrier === carrier.carrier_id)?'checked-btn':'na'}
                            onClick={(event)=> {
                                event.stopPropagation();
                                dispatch(updateState({ 
                                    driverFilters: {
                                    ...driverFilters, 
                                    driversTab: {...driverFilters['driversTab'], 
                                    checkIn: (filters.checkIn === 'Checked-In' && filters.carrier === carrier.carrier_id ) ? 'All':'Checked-In',
                                    [itemType === 'zone'?"zone":"customer"]: filters.checkIn === 'Checked-In' && filters.carrier === carrier.carrier_id ? 'All': item.id,
                                    carrier: (filters.checkIn === 'Checked-In' && filters.carrier === carrier.carrier_id)? "All": carrier.carrier_id
                                    }}}))
                            }}
                        >
                            Checked In
                        </small>
                        <small className={(filters.checkIn === 'Checked-out' && filters.carrier === carrier.carrier_id)? 'checked-btn':'na'}
                            onClick={(event)=> {
                                event.stopPropagation();
                                dispatch(updateState({ 
                                    driverFilters: {
                                    ...driverFilters, 
                                    driversTab: {...driverFilters['driversTab'], 
                                    checkIn: (filters.checkIn === 'Checked-out' && filters.carrier === carrier.carrier_id )? 'All':'Checked-out',
                                    [itemType === 'zone'?"zone":"customer"]: filters.checkIn === 'Checked-out' && filters.carrier === carrier.carrier_id? 'All' : item.id,
                                    carrier: (filters.checkIn === 'Checked-out' && filters.carrier === carrier.carrier_id)? "All": carrier.carrier_id
                                }}}))
                            }}
                        >
                            Checked Out
                        </small>

                        </div>
                        <Row className= {filters.shift === 'AM' && filters.carrier === carrier.carrier_id?'d-grid ': 'd-grid'}>
                        <Col span={17}>
                            <div className='zone-details'>
                                <small className={filters.shift === 'AM' && filters.carrier === carrier.carrier_id?'am target-div-highlight':'am'}
                                 onClick={(event)=> {
                                    updateFilters(event, 'AM_CARRIER', {carrier: carrier.carrier_id, shift: 'AM'})
                                }}
                                >AM</small>
                                <span>Check in <span style={{color:"green",fontWeight:'bold'}}>
                                    { carrierTarget.AM ? carrierTarget.AM.checkedIn ?? 0 : 0}
                                    </span>  • 
                                         Actual <span style={{color:"orange",fontWeight:'bold'}}>
                                        { carrierTarget.AM ? carrierTarget.AM.actual ?? 0 : 0} 
                                    </span>    •
                                         Target <span style={{color:"red",fontWeight:'bold'}}>{carrier?.am_target ?? 0}</span> 
                                </span>
                            </div>
                        </Col>
                        <Col span={7}>
                            <GetProgressBar barNum={[
                                carrierTarget.AM ? carrierTarget.AM.checkedIn ?? 0 : 0,
                                carrierTarget.AM ? carrierTarget.AM.actual ?? 0 : 0,
                                carrier.am_target]}/>
                        </Col>
                        </Row>
                        <Row className= {filters.shift === 'PM' && filters.carrier === carrier.carrier_id?'d-grid ': 'd-grid'}>
                        <Col span={17}>
                            <div className= {'zone-details'}>
                                <small className={filters.shift === 'PM' && filters.carrier === carrier.carrier_id?'pm target-div-highlight': 'pm'}
                                onClick={(event)=> {
                                    updateFilters(event, 'PM_CARRIER', {carrier: carrier.carrier_id, shift: 'PM'})
                                }}
                                >PM</small>
                                <span>Check in <span style={{color:"green",fontWeight:'bold'}}>
                                    { carrierTarget.PM ? carrierTarget.PM.checkedIn ?? 0 : 0 }
                                    </span> • 
                                        Actual <span style={{color:"orange",fontWeight:'bold'}}>
                                        { carrierTarget.PM ? carrierTarget.PM.actual ?? 0 : 0 }
                                            </span> •
                                        Target <span style={{color:"red",fontWeight:'bold'}}>{carrier.pm_target}</span> 
                                </span>
                            </div>
                        </Col>
                        <Col span={7}>
                            <GetProgressBar barNum={[carrierTarget.PM ? carrierTarget.PM.checkedIn ?? 0 : 0 ,
                            carrierTarget.PM ? carrierTarget.PM.actual ?? 0 : 0,
                            carrier.pm_target]}/>
                        </Col>
                        
                        </Row>
                    </div>)
                    })}
            </div>
            : 
            <div className="empty-data">    
                <h2>No Carrier Linked</h2>
              <p>There are no carrier for this zone</p>
            </div>
            }
           
        </>
        : null}
        </Panel>
    </Collapse>)
    
}

const Zone = ({item, index, toggleCollapse, collapseZones, itemType}) =>{
    const carrierTargetInfo = useSelector(state=> state.mainReducer.carrierTargetInfo)
    const tenantTargetInfo = useSelector(state=> state.mainReducer.tenantTargetInfo)
    const [linkedCarrier, setLinkedCarrier] = useState([])
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
          accept: "driver",
          drop: () => ({[itemType]: item, index }),
          collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: item? item.status === true ? monitor.canDrop() : false: false,
          }),
        }),
        [item]
      );
      const isActive = canDrop && isOver;
      let backgroundColor =  "white";
      let border = "";
      let borderRadius = "";
      let marginLeft = "";
      if (isActive) {
        backgroundColor = "rgb(230, 235, 238)";
        border = "1px solid #093254";
        borderRadius = "4px";
        marginLeft = "8px";
      }
    const getCarrier = async() => {
        try{
            let carriers = await DataStore.query(CatHerd, c=>
            c.and(c => [
                c.gsi1sk.eq(item.id),
                c.classification.eq('TARGET')
            ]))
            setLinkedCarrier(carriers)
        }catch(e){
            console.log(e)
        }
    }
    let subscription;
    
    useEffect(() =>{
        getCarrier() 
        try {
            subscription = DataStore.observe(CatHerd).subscribe((msg) => {
              if (
                msg.opType === literals.DATASTORE.OPTYPE.DELETE ||
                msg.opType === literals.DATASTORE.OPTYPE.INSERT ||
                msg.opType === literals.DATASTORE.OPTYPE.UPDATE
              ) {
                if(msg.element.classification === "TARGET"){
                    getCarrier();
                }
              }
            });
          } catch (err) {
            console.log("subscription check error");
          }
        return () => {
        subscription.unsubscribe();
        };
    },[item])
    return(
        <div
            className="card-list"
            key={uuidv4()}
            ref={itemType === 'zone'?drop: null}
            role={itemType}
            style={{
                cursor: "pointer",
                border,
                backgroundColor,
                borderRadius,
                marginLeft,
              }}
            data-testid={`${itemType}_${index}`}
        >
            <List.Item style={{ padding: "0px" }} key={uuidv4()}>
            <List.Item.Meta key={uuidv4()} title={<ListContent 
            key={uuidv4()}
            item={item}
            itemType={itemType}
            collapseZones={collapseZones}
            zoneIndex={index}
            toggleCollapse={toggleCollapse}
            linkedCarrier={linkedCarrier.sort((a, b) => a.carrier_name.localeCompare(b.carrier_name))}
            carrierTargetInfo = {carrierTargetInfo}
            tenantTargetInfo = {tenantTargetInfo}
            />
            } />
            </List.Item>
        
        </div>
    )
}
export default Zone;