import { Tooltip, Col, Row } from "antd";
import { projectLiterals as literals } from "../../utils/projectLiterals";
const StatusTooltip = ({driver}) => {
  return (
    <Tooltip
      placement="left"
      color="#fff"
      title={
        driver.is_assigned ? (
          driver.load_no ? (
            <Col>
              <Row>
                <span>
                  <b>Job:</b>
                  {` ${driver.job_name}`}
                </span>
              </Row>
              <Row>
                <span>
                  <b>Load:</b>
                  {` ${driver.load_no}`}
                </span>
              </Row>
            </Col>
          ) : (
            literals.TOOLTIP.LOAD_ASSIGNED
          )
        ) : driver.check_in === "1" ? (
          literals.TOOLTIP.CHECK_IN
        ) : (
          literals.TOOLTIP.CHECK_OUT
        )
      }
    >
      <span
        className="dotsss"
        style={{
          backgroundColor: driver.is_assigned
            ? "#4285F4"
            : driver.check_in === "1"
            ? "#16AE63"
            : "#F73B3B",
        }}
      ></span>
    </Tooltip>
  );
};
export default StatusTooltip;
