import { Menu } from "antd";
import { useDispatch } from "react-redux";
import {
  checkInOutDriver,
  makeDriverUnavaiable,
  markDriverUnassigned,
} from "../../services/apiCalls";
import { updateState } from "../../store/actions/main_actions";
import {useJitsu} from "@jitsu/jitsu-react"

const DriverMenu = ({ driver, unassignOption }) => {
  const dispatch = useDispatch();
  const {analytics} = useJitsu()
  
  const driverMenuItems = [
    {
      label: driver.flag_type !== null? "Remove Flag" : "Flag Driver",
      key: "flag",
    },
    {
      label: "Unassign Driver",
      key: "unassign",
      disabled: !unassignOption,
    },
    {
      label:
        driver.on_duty === "1"
          ? "Mark driver as unavailable"
          : "Mark driver as Available",
      key: "avaiblility",
    },
    {
      label: "Check Driver Out",
      key: "checkout",
      disabled: driver.check_in === "0" || driver.check_in === "2" ? true : false,
    },
    {
      label: "Check Driver In",
      key: "checkin",
      disabled: driver.check_in === "1" ? true : false,
    },
    {
      label: "Report Data Issue",
      key: "report"
    },
  ];
  const onDriverMenuSelect = (e) => {
    switch (e.key) {
      case "unassign":
        dispatch(updateState({ driverListLoading: true }));
        markDriverUnassigned(driver);
        analytics.track('Drivers Removed from Tenant',{
          "Drivers": {id: driver.id, cat: driver.cat, driver_name: driver.driver_name, gsi1sk: 'ZONE#', triggerType: "UNASSIGNED"},
        'Description': `Drivers KickedOut of Tenant on clicking on driver Menu UnAssigned Button`})
      
        break;
      case "avaiblility":
        makeDriverUnavaiable(driver);
        break;
      case "checkin":
        checkInOutDriver(driver, 1);
        break;
      case "checkout":
        checkInOutDriver(driver, 0);
        break;
      case "flag":
        dispatch(updateState({ 
          menuDriver: driver,
          openMenuModal: true,
          menuModaltype: e.key,
        }));
        break;
      case "report":
        dispatch(updateState({ 
          menuDriver: driver,
          openMenuModal: true,
          menuModaltype: e.key,
        }));
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Menu
        key={"driverlist-dropdown-menu" + driver.id}
        onClick={(e) => {
          onDriverMenuSelect(e);
        }}
        items={driverMenuItems}
      />
    </>
  );
};
export default DriverMenu;
