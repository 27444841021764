/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCatHerd = /* GraphQL */ `
  mutation CreateCatHerd(
    $input: CreateCatHerdInput!
    $condition: ModelCatHerdConditionInput
  ) {
    createCatHerd(input: $input, condition: $condition) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCatHerd = /* GraphQL */ `
  mutation UpdateCatHerd(
    $input: UpdateCatHerdInput!
    $condition: ModelCatHerdConditionInput
  ) {
    updateCatHerd(input: $input, condition: $condition) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCatHerd = /* GraphQL */ `
  mutation DeleteCatHerd(
    $input: DeleteCatHerdInput!
    $condition: ModelCatHerdConditionInput
  ) {
    deleteCatHerd(input: $input, condition: $condition) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGlobalLookups = /* GraphQL */ `
  mutation CreateGlobalLookups(
    $input: CreateGlobalLookupsInput!
    $condition: ModelGlobalLookupsConditionInput
  ) {
    createGlobalLookups(input: $input, condition: $condition) {
      id
      cat
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGlobalLookups = /* GraphQL */ `
  mutation UpdateGlobalLookups(
    $input: UpdateGlobalLookupsInput!
    $condition: ModelGlobalLookupsConditionInput
  ) {
    updateGlobalLookups(input: $input, condition: $condition) {
      id
      cat
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGlobalLookups = /* GraphQL */ `
  mutation DeleteGlobalLookups(
    $input: DeleteGlobalLookupsInput!
    $condition: ModelGlobalLookupsConditionInput
  ) {
    deleteGlobalLookups(input: $input, condition: $condition) {
      id
      cat
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReports = /* GraphQL */ `
  mutation CreateReports(
    $input: CreateReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    createReports(input: $input, condition: $condition) {
      id
      type
      zone_name
      driver_id
      driver_name
      description
      report_status
      carrier_id
      carrier_name
      tenant_id
      DD_current_load
      DD_current_load_status
      PD_current_load
      PD_current_load_status
      DD_check_in
      PD_check_in
      DD_driver_available
      PD_driver_available
      DD_ready_for_load_time
      PD_ready_for_load_time
      DD_last_load_no
      PD_last_load_no
      DD_last_job_name
      PD_last_job_name
      PD_last_delivered_date
      DD_last_delivered_date
      zone_shift
      DD_driver_shift
      PD_driver_shift
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReports = /* GraphQL */ `
  mutation UpdateReports(
    $input: UpdateReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    updateReports(input: $input, condition: $condition) {
      id
      type
      zone_name
      driver_id
      driver_name
      description
      report_status
      carrier_id
      carrier_name
      tenant_id
      DD_current_load
      DD_current_load_status
      PD_current_load
      PD_current_load_status
      DD_check_in
      PD_check_in
      DD_driver_available
      PD_driver_available
      DD_ready_for_load_time
      PD_ready_for_load_time
      DD_last_load_no
      PD_last_load_no
      DD_last_job_name
      PD_last_job_name
      PD_last_delivered_date
      DD_last_delivered_date
      zone_shift
      DD_driver_shift
      PD_driver_shift
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReports = /* GraphQL */ `
  mutation DeleteReports(
    $input: DeleteReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    deleteReports(input: $input, condition: $condition) {
      id
      type
      zone_name
      driver_id
      driver_name
      description
      report_status
      carrier_id
      carrier_name
      tenant_id
      DD_current_load
      DD_current_load_status
      PD_current_load
      PD_current_load_status
      DD_check_in
      PD_check_in
      DD_driver_available
      PD_driver_available
      DD_ready_for_load_time
      PD_ready_for_load_time
      DD_last_load_no
      PD_last_load_no
      DD_last_job_name
      PD_last_job_name
      PD_last_delivered_date
      DD_last_delivered_date
      zone_shift
      DD_driver_shift
      PD_driver_shift
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createActivityLog = /* GraphQL */ `
  mutation CreateActivityLog(
    $input: CreateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    createActivityLog(input: $input, condition: $condition) {
      id
      skey
      type
      actionType
      platform
      user_name
      user_id
      reason
      driver_tenant
      driver_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateActivityLog = /* GraphQL */ `
  mutation UpdateActivityLog(
    $input: UpdateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    updateActivityLog(input: $input, condition: $condition) {
      id
      skey
      type
      actionType
      platform
      user_name
      user_id
      reason
      driver_tenant
      driver_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteActivityLog = /* GraphQL */ `
  mutation DeleteActivityLog(
    $input: DeleteActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    deleteActivityLog(input: $input, condition: $condition) {
      id
      skey
      type
      actionType
      platform
      user_name
      user_id
      reason
      driver_tenant
      driver_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
