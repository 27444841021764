import { Modal, Button, Select } from "antd";
import { useState } from "react";

const HaulingCostModal = ({openModal, setOpenModal, body, assignLoadtoDriver, costList, setCostList}) =>{
    const [loading, setLoading] = useState(false)
    const [cost, setCost] = useState(null)
   
    return(
        <Modal
        centered
        className="hauling-modal"
        id={`report_modal`}
        width={500}
        open={openModal}
        onCancel={()=> {setOpenModal(false); setCostList([])}}
        footer={[
          <Button
            key="back"
            size="large"
            onClick={()=> setOpenModal(false)}
          >
            Cancel
          </Button>,
          <Button
            loading={loading}
            key="submit"
            size="large"
            type="primary"
            disabled={(cost === null)?true:false}
            onClick={async(e)=>{
                assignLoadtoDriver({...body,  "equipment_basin_id": cost})
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <h1>Select Hauling Cost</h1>
        <Select
            showSearch
            placeholder="Select one"
            optionFilterProp="children"
            onChange={(value)=>setCost(value)}
            filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={costList.map((item)=>{return {value:item.equipment_basin_id, label:item.name}})}
        />
        </Modal>
    )
}
export default HaulingCostModal;