import { readyForLoadTime, parseDateCST } from "../../utils/date";
import { formatPhoneNumber } from "../../utils/phoneNumber";
import QueueTime from "../QueueTime";
import { Row, Col } from "antd";
const moment = require("moment-timezone");


const DriverExpandInfo = ({ driver, showUnassigned, view }) => {
  return (
    <Row className="d-grid" >
      <Col span={showUnassigned ? 4 : 8} order={2}>
        <span className="driver-detail-heading">Avg. Turns Per Shift</span>
        <br />
        {driver.turns_per_shift ?? "n/a"}
      </Col>
      {view === "Drivers" ? (
        <>
          <Col span={showUnassigned ? 4 : 8} order={1}>
            <span className="driver-detail-heading">Truck #, Trailer #</span>
            <br />
           
              {driver.truck_number}, {driver.trailer_number}
            
          </Col>
          <Col span={showUnassigned ? 4 : 8} order={4}>
            <span className="driver-detail-heading">Phone</span>
            <br />
            {formatPhoneNumber(driver.phone_no)}
          </Col>
          <Col span={showUnassigned ? 4 : 8} order={5}>
          <span className="driver-detail-heading"> Last Load Date </span>
            <br />
            {driver.last_delivered_date !== '' && driver.last_delivered_date ?
            `${moment.utc(driver.last_delivered_date).format("DD MMM YYYY, HH:mm")}`
            : 'n/a'}
          </Col>
        </>
      ) : (
        <>
          <Col span={showUnassigned ? 4 : 8} order={1}>
            <span className="driver-detail-heading">Ready for Load time</span>
            <br />
            {driver.is_assigned
              ? "-"
              : !isNaN(readyForLoadTime(driver))
              ? moment.utc(readyForLoadTime(driver)).format(
                  "MM/DD/YYYY hh:mm A"
                )
              : "n/a"}
          </Col>
          <Col span={showUnassigned ? 4 : 8} order={3}>
            <span className="driver-detail-heading"> Queue Time </span>
            <br />
            {driver.check_in === "1" ? <QueueTime user={driver} /> : "n/a"}
          </Col>

          <Col span={showUnassigned ? 4 : 8} order={6}>
            <span className="driver-detail-heading"> Last Load Date </span>
            <br />
            {driver.last_delivered_date !== '' && driver.last_delivered_date ?
            `${moment.utc(driver.last_delivered_date).format("DD MMM YYYY, HH:mm")}`
            : 'n/a'}
          </Col>
        </>
      )}
      <Col span={showUnassigned ? 4 : 8} order={4}>
        <span className="driver-detail-heading"> Last Load Job </span>
        <br />
       <span className="last-load-job"> {driver.last_load_job ?? "n/a"} </span>
      </Col>

      <Col span={showUnassigned ? 4 : 8} order={5}>
        <span className="driver-detail-heading"> Last Load Delivered </span>
        <br />
        {driver.last_load_delivered ?? "n/a"}
      </Col>
    </Row>
  );
};
export default DriverExpandInfo;
