import driverAvatar from "../../images/default-driver.svg";
import {
    Avatar,
  } 
from "antd";
const DriverImage = ({className, imageUrl}) =>{
    const url = imageUrl !== '' && imageUrl !== undefined ?
            (!imageUrl.includes(process.env.REACT_APP_PD_BASE_URL) && !imageUrl.includes('https'))?
                `${process.env.REACT_APP_PD_BASE_URL}image_bucket/download/users__${imageUrl}?X-API-KEY=${process.env.REACT_APP_X_API_KEY}`
            : imageUrl
        :driverAvatar;
    return(
        <Avatar className={className? className: ''}
                alt='driver-image'
                src={url}   
        />
    )
}
export default DriverImage