import { DataStore } from 'aws-amplify/datastore';
import { generateClient } from 'aws-amplify/api';
import { CatHerd } from '../models';
import { updateState } from '../store/actions/main_actions';
import store from '../store/store.js';
import { apiRequestAxio } from './axioCall';
import { notification } from 'antd';
import awsmobile from '../aws-exports';
import moment from 'moment-timezone';
import axios from 'axios';
import amplifyconfig from '../amplifyconfiguration.json';

import { makeZoneBaseTreeAggregation } from '../helpers/helperFunc';
import { createReports, updateCatHerd } from '../graphql/mutations';
import {
    readyForLoadTime,
    dateConverted,
    getActiveShiftOld
} from '../utils/date';
import {
    getCatherdTuple,
    getPaginatedData,
    logDriverActivity,
    updateCatherdTuple
} from './appSyncCalls';
import {
    dataByCarrierAndClass,
    dataByDDTenant,
    dataByZone,
    databyDriverId,
    getCatHerdByClassificationAndGS1SK
} from '../graphql/queries';
import { projectLiterals as literals } from '../utils/projectLiterals.js';

const API = generateClient();
export const getAWSApiLinks = (gatewayName) => {
    let gatewayApi =
        amplifyconfig?.aws_cloud_logic_custom ??
        awsmobile?.aws_cloud_logic_custom;
    return gatewayApi.find((e) => e.name === gatewayName)?.endpoint ?? null;
};

export const updateSelectedZone = (zone) => {
    let selectedZone = store.getState().mainReducer.selectedZone;
    if (
        selectedZone !== null &&
        zone.id &&
        zone.id === selectedZone.id &&
        zone._version !== selectedZone._version
    ) {
        store.dispatch(updateState({ selectedZone: zone }));
    }
};
export const fetchDrivers = async () => {
    try {
        let selectedBoardZone = store.getState().mainReducer.selectedBoardZone;
        let selectedCustomer = store.getState().mainReducer.selectedCustomer;
        let userData = store.getState()?.loginReducer?.userData;
        let userType = store.getState()?.loginReducer?.userType;
        let showUnassigned = store.getState().mainReducer.showUnassigned;
        let filters = store.getState().mainReducer.driverFilters.boardTab;
        let variables = {};
        let query = null,
            queryName = null;
        if (userType.tenant.includes(userData.company_type)) {
            if (selectedBoardZone !== null) {
                query = getCatHerdByClassificationAndGS1SK;
                queryName = 'getCatHerdByClassificationAndGS1SK';
                variables = {
                    classification: literals.DATASTORE.CLASSIFICATION.DRIVER,
                    gsi1sk: {
                        eq:
                            selectedBoardZone?.id ??
                            `${literals.DATASTORE.CLASSIFICATION.ZONE}#`
                    },
                    filter: {
                        triggerType: { eq: literals.DATASTORE.VALUES.ASSIGNED },
                        dd_tenant: {
                            eq: `${literals.DATASTORE.VALUES.TENANT}#${userData.company_id}`
                        },
                        check_in: { eq: '1' },
                        is_assigned: {
                            [filters.view_type === 'Assigned'
                                ? 'eq'
                                : 'ne']: true
                        }
                    }
                };
            } else {
                query = dataByDDTenant;
                queryName = 'dataByDDTenant';
                variables = {
                    dd_tenant: `${literals.DATASTORE.VALUES.TENANT}#${userData.company_id}`,
                    cat: {
                        beginsWith: `${literals.DATASTORE.CLASSIFICATION.DRIVER}#`
                    },
                    filter: {
                        gsi1sk: { eq: `ZONE#` },
                        triggerType: { eq: literals.DATASTORE.VALUES.ASSIGNED }
                    }
                };
            }
        } else {
            query = dataByZone;
            queryName = 'dataByZone';
            if (selectedCustomer && selectedBoardZone) {
                variables = {
                    gsi1sk: `${selectedBoardZone.id}`,
                    cat: {
                        eq: `DRIVER#${selectedCustomer}#${userData.company_id}`
                    },
                    filter: {
                        check_in: { eq: '1' },
                        is_assigned: {
                            [filters.view_type === 'Assigned'
                                ? 'eq'
                                : 'ne']: true
                        }
                    }
                };
            } else {
                query = dataByCarrierAndClass;
                queryName = 'dataByCarrierAndClass';
                variables = {
                    carrier_id: userData.company_id,
                    classification: {
                        eq: `${literals.DATASTORE.CLASSIFICATION.DRIVER}`
                    },
                    filter: {
                        triggerType: { eq: null },
                        dd_tenant: {
                            eq: `${literals.DATASTORE.VALUES.TENANT}#`
                        }
                    }
                };
            }
        }
        let drivers = await getPaginatedData({
            variables,
            query,
            queryName
        });
        if (userType.carrier.includes(userData.company_type)) {
            drivers = drivers.reduce(
                (acc, item) => [
                    ...acc.filter(
                        ({ driver_id }) => driver_id !== item.driver_id
                    ),
                    item
                ],
                []
            );
        }
        if (drivers) {
            store.dispatch(
                updateState({ drivers: drivers, boardLoading: false })
            );

            if (selectedCustomer === null || showUnassigned) {
                store.dispatch(
                    updateState({ unassignedDriverCount: drivers.length })
                );
            }
        }
        store.dispatch(updateState({ boardLoading: false }));
    } catch (error) {
        console.log('error in fetching drivers', error);
        store.dispatch(updateState({ boardLoading: false }));
    }
};
export const fetchLoads = async (zoneId) => {
    try {
        let loadZoneFilter = store.getState().mainReducer.loadZoneFilter;
        let data = await getPaginatedData({
            variables: {
                classification: literals.DATASTORE.CLASSIFICATION.LOAD,
                gsi1sk: { eq: zoneId ?? loadZoneFilter?.id },
                filter: {
                    is_assigned: { ne: true },
                    or: [
                        { load_status: { eq: '0' } },
                        { load_status: { eq: '3' } }
                    ]
                }
            },
            query: getCatHerdByClassificationAndGS1SK,
            queryName: 'getCatHerdByClassificationAndGS1SK'
        });
        if (data) {
            let loads = data.sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
            store.dispatch(updateState({ loads }));
        } else {
            store.dispatch(updateState({ loads: [] }));
        }
    } catch (error) {
        console.log('error in fetching drivers', error);
        store.dispatch(updateState({ loads: [] }));
    }
};

export const fetchJobs = async () => {
    try {
        let selectedBoardZone = store.getState().mainReducer.selectedBoardZone;
        let data = await getPaginatedData({
            variables: {
                classification: literals.DATASTORE.CLASSIFICATION.JOB,
                gsi1sk: { eq: selectedBoardZone?.id },
                filter: {
                    is_assigned: { ne: true },
                    or: [
                        { working_status: { eq: '1' } },
                        { working_status: { eq: '2' } },
                        { working_status: { eq: '4' } }
                    ]
                }
            },
            query: getCatHerdByClassificationAndGS1SK,
            queryName: 'getCatHerdByClassificationAndGS1SK'
        });

        if (data) {
            let jobs = data.sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
            store.dispatch(updateState({ jobs }));
        } else {
            store.dispatch(updateState({ jobs: [] }));
        }
    } catch (error) {
        console.log('error in fetching jobs', error);
        store.dispatch(updateState({ jobs: [] }));
    }
};

export const reportDriver = async (driver) => {
    try {
        let dynamoDriver = await getCatherdTuple(driver.id, driver.cat);
        let response = await apiRequestAxio(
            'GET',
            `pch/pch_sync/driver?driver_id=[${driver.driver_id}]`,
            {}
        );
        if (response?.data?.data.length > 0 && dynamoDriver) {
            const zones = store.getState().mainReducer.zones;
            let pdDriver = response?.data?.data[0];
            let loadDetail = [];
            if (pdDriver.accepted_load_id !== '') {
                const loadResponse = await apiRequestAxio(
                    'GET',
                    `pch/pch_sync/load?load_id=[${pdDriver.accepted_load_id}]`,
                    {}
                );
                loadDetail = loadResponse.data.data ?? [];
            }
            let zone_shift = 'NA';
            if (dynamoDriver.gsi1sk !== 'ZONE#') {
                let zone = await DataStore.query(CatHerd, (zone) =>
                    zone.and((zone) => [
                        zone.id.eq(dynamoDriver.gsi1sk),
                        zone.cat.eq('ZONE')
                    ])
                );
                zone_shift = getActiveShiftOld(zone[0] ?? null);
            }
            let res = await API.graphql({
                query: createReports,
                variables: {
                    input: {
                        id: dynamoDriver.driver_id,
                        type: `${moment()
                            .tz('America/Chicago')
                            .format('DD MMMM YYYY, HH:MM')}`,
                        zone_name:
                            dynamoDriver.gsi1sk &&
                            dynamoDriver.gsi1sk !== 'ZONE#'
                                ? zones.filter(
                                      (zone) => zone.id === dynamoDriver.gsi1sk
                                  )[0]?.z_name
                                : 'None',
                        description: driver.description ?? 'n/a',
                        driver_id: dynamoDriver.driver_id,
                        driver_name: dynamoDriver.driver_name,
                        report_status: 'todo',
                        carrier_id: dynamoDriver.carrier_id,
                        carrier_name: dynamoDriver.carrier_name,
                        DD_current_load: dynamoDriver.load_no ?? 'n/a',
                        DD_current_load_status:
                            dynamoDriver.assign_load_status ?? 'n/a',
                        PD_current_load:
                            loadDetail.length > 0
                                ? loadDetail[0].load_no ?? 'n/a'
                                : 'n/a',
                        PD_current_load_status:
                            pdDriver.driver_load_status ?? 'n/a',
                        tenant_id: dynamoDriver.tenant_id,
                        DD_check_in: dynamoDriver.check_in,
                        PD_check_in: pdDriver.check_in,
                        DD_driver_available: dynamoDriver.on_duty,
                        PD_driver_available: pdDriver.on_duty,
                        DD_ready_for_load_time: `${moment
                            .utc(readyForLoadTime(dynamoDriver))
                            .format('DD MMMM YYYY, hh:mm')}`,
                        PD_ready_for_load_time: `${moment
                            .utc(readyForLoadTime(pdDriver))
                            .format('DD MMMM YYYY, hh:mm')}`,
                        DD_last_load_no: dynamoDriver.last_load_delivered,
                        PD_last_load_no: pdDriver.last_load_no,
                        DD_last_job_name: dynamoDriver.last_load_job,
                        PD_last_job_name: pdDriver.last_job_name,
                        DD_last_delivered_date: `${moment
                            .utc(dynamoDriver.last_delivered_date)
                            .format('DD MMMM YYYY, hh:mm')}`,
                        PD_last_delivered_date: `${moment(
                            pdDriver.last_delivered_date
                        ).format('DD MMMM YYYY, hh:mm')}`,
                        zone_shift: zone_shift,
                        DD_driver_shift: dynamoDriver.shift,
                        PD_driver_shift: pdDriver.shift
                    }
                },
                authMode: literals.API.AUTHMODE,
                authToken:
                    store.getState().loginReducer.userData.token || 'null'
            });
            // console.log(res)
            if (res?.data?.createReports) {
                notification.open({
                    description: `Driver ${driver.driver_name} Reported`,
                    placement: 'bottomLeft'
                });
                return true;
            } else
                notification.open({
                    description: `Unable to report ${driver.driver_name}, try later`,
                    placement: 'bottomLeft'
                });
            return false;
        }
    } catch (e) {
        console.log(e);
        notification.open({
            description: `Unable to report ${driver.driver_name}, try later`,
            placement: 'bottomLeft'
        });
        return false;
    }
};

export const unassignedZoneDriver = async (drivers) => {
    try {
        let userData = store.getState()?.loginReducer?.userData;
        let userType = store.getState()?.loginReducer?.userType;
        let dynamoPromisesArray = [];
        let observeIds = [];
        if (userType.tenant.includes(userData.company_type)) {
            for (let driver of drivers) {
                if (drivers.length > 1) {
                    observeIds.push(driver.id);
                }
                dynamoPromisesArray.push(
                    updateCatherdTuple({
                        id: driver.id,
                        cat: driver.cat,
                        gsi1sk: 'ZONE#',
                        z_name: null,
                        type: `DRIVER_ZONE#`
                    })
                );
            }
        } else {
            for (let driver of drivers) {
                if (drivers.length > 1) {
                    observeIds.push(driver.id);
                }
                dynamoPromisesArray.push(
                    updateCatherdTuple({
                        id: driver.id,
                        cat: driver.cat,
                        gsi1sk: 'ZONE#',
                        z_name: null,
                        type: `DRIVER_ZONE#`
                    })
                );
            }
        }
        store.dispatch(
            updateState({
                observeIds: [...observeIds],
                observeIdsCount: observeIds.length
            })
        );
        await Promise.allSettled(dynamoPromisesArray).then((results) => {
            let updatedDriverList = {};
            results.forEach((driver) => {
                updatedDriverList[driver.id] = driver;
            });
            store.dispatch(
                updateState({
                    multiSelectDriver: [],
                    checkAll: false
                })
            );
        });
    } catch (e) {
        console.log('unassigned Driver failed', e);
    }
};

export const fetchShiftDrivers = async (sync, records) => {
    try {
        let userData = store.getState()?.loginReducer?.userData;
        let userType = store.getState()?.loginReducer?.userType;
        let selectedCustomer = store.getState().mainReducer.selectedCustomer;
        let data = [];
        if (!sync) {
            let driverTabDrivers = {};
            if (userType.tenant.includes(userData.company_type)) {
                data = await getPaginatedData({
                    variables: {
                        dd_tenant: `${literals.DATASTORE.VALUES.TENANT}#${userData.company_id}`,
                        cat: {
                            beginsWith: `${literals.DATASTORE.CLASSIFICATION.DRIVER}#${userData.company_id}#`
                        },
                        filter: {
                            triggerType: {
                                eq: literals.DATASTORE.VALUES.ASSIGNED
                            }
                        }
                    },
                    query: dataByDDTenant,
                    queryName: 'dataByDDTenant'
                });
            } else {
                if (selectedCustomer !== null) {
                    data = await getPaginatedData({
                        variables: {
                            dd_tenant: `${literals.DATASTORE.VALUES.TENANT}#${selectedCustomer}`,
                            cat: {
                                beginsWith: `${literals.DATASTORE.CLASSIFICATION.DRIVER}#${selectedCustomer}#`
                            },
                            filter: {
                                triggerType: {
                                    eq: literals.DATASTORE.VALUES.ASSIGNED
                                },
                                carrier_id: { eq: userData.company_id },
                                gsi1sk: { ne: 'ZONE#' }
                            }
                        },
                        query: dataByDDTenant,
                        queryName: 'dataByDDTenant'
                    });
                } else {
                    // no zone selected
                    data = await getPaginatedData({
                        variables: {
                            // dd_tenant: `${literals.DATASTORE.VALUES.TENANT}#${selectedCustomer}`,
                            carrier_id: userData.company_id,
                            classification: {
                                eq: literals.DATASTORE.CLASSIFICATION.DRIVER
                            },
                            filter: {
                                triggerType: {
                                    ne: literals.DATASTORE.VALUES.NA
                                },
                                gsi1sk: { eq: 'ZONE#' }
                            }
                        },
                        query: dataByCarrierAndClass,
                        queryName: 'dataByCarrierAndClass'
                    });
                }
                console.log(data.length);
            }
            if (userType.carrier.includes(userData.company_type)) {
                data = data.reduce(
                    (acc, item) => [
                        ...acc.filter(
                            ({ driver_id }) => driver_id !== item.driver_id
                        ),
                        item
                    ],
                    []
                );
            }
            data.forEach((item) => (driverTabDrivers[item.id] = item));
            makeZoneBaseTreeAggregation(data);
            store.dispatch(
                updateState({ driverTabDrivers: { ...driverTabDrivers } })
            );
        } else {
            // console.log(records);
        }
    } catch (e) {
        console.log(e);
    }
    return [];
};

export const markDriverUnassigned = async (driver) => {
    try {
        let updatedDriver = await updateCatherdTuple({
            id: driver.id,
            cat: driver.cat,
            triggerType: 'UNASSIGNED',
            gsi1sk: 'ZONE#',
            z_name: null,
            type: 'DRIVER_ZONE#'
        });
        if (updatedDriver.triggerType === 'UNASSIGNED') {
            notification.open({
                description: 'Driver Unassigned Successfully',
                placement: 'bottomLeft'
            });
        }
        store.dispatch(
            updateState({
                driverListLoading: false,
                multiSelectDriver: [],
                checkAll: false
            })
        );
    } catch (e) {
        console.log(e);
        notification.open({
            description: 'Driver Unassigned Failed, try again later',
            placement: 'bottomLeft'
        });
    }
};

export const markBulkDriversUnassigned = async () => {
    try {
        store.dispatch(
            updateState({
                driverListLoading: true
            })
        );
        // let driverTabDrivers = store.getState()?.mainReducer.driverTabDrivers;
        let driversList = store.getState()?.mainReducer.multiSelectDriver;
        let promiseArray = [];
        let observeIds = [];
        let trackDrivers = [];
        await driversList.forEach((updatedValues) => {
            observeIds.push(updatedValues.id);
            trackDrivers.push({
                id: updatedValues.id,
                cat: updatedValues.cat,
                driver_name: updatedValues.driver_name,
                gsi1sk: 'ZONE#',
                z_name: null,
                triggerType: 'UNASSIGNED'
            });
            promiseArray.push(
                API.graphql({
                    query: updateCatHerd,
                    variables: {
                        input: {
                            id: updatedValues.id,
                            cat: updatedValues.cat,
                            _version: updatedValues._version,
                            type: `DRIVER_ZONE#`,
                            gsi1sk: 'ZONE#',
                            z_name: null,
                            triggerType: 'UNASSIGNED'
                        }
                    },
                    authMode: literals.API.AUTHMODE,
                    authToken:
                        store.getState().loginReducer.userData.token || 'null'
                })
            );
        });

        store.dispatch(
            updateState({
                observeIds: [...observeIds],
                observeIdsCount: observeIds.length
            })
        );
        await Promise.all(promiseArray)
            .then((data) => {
                // console.log("PD drivers updated",data)
                store.dispatch(
                    updateState({
                        driverListLoading: false,
                        multiSelectDriver: [],
                        checkAll: false
                    })
                );
            })
            .catch((rejectedValue) => {
                console.log('rejectedValue', rejectedValue);
            });
        return trackDrivers;
    } catch (e) {
        console.log(e);
    }
};
export const makeDriverUnavaiable = async (driver) => {
    try {
        let body = {
            driver_id: driver.driver_id,
            duty: driver.on_duty === '1' ? '0' : '1'
        };
        let response = await apiRequestAxio(
            'POST',
            'pch/pch_integrations/driver_duty',
            body
        );
        if (response?.data?.code === 200 || response?.data?.code === '200') {
            notification.open({
                description:
                    driver.on_duty === '1'
                        ? literals.MESSAGE.DRIVER_UNAVAILABLE
                        : literals.MESSAGE.DRIVER_AVAILABLE,
                placement: 'bottomLeft'
            });
        } else {
            notification.open({
                description:
                    driver.on_duty === '1'
                        ? literals.MESSAGE.DRIVER_UNAVAILABLE_FAILED
                        : literals.MESSAGE.DRIVER_AVAILABLE_FAILED,
                placement: 'bottomLeft'
            });
        }
    } catch (e) {
        console.log(e);
        notification.open({
            description:
                driver.on_duty === '1'
                    ? literals.MESSAGE.DRIVER_UNAVAILABLE_FAILED
                    : literals.MESSAGE.DRIVER_AVAILABLE_FAILED,
            placement: 'bottomLeft'
        });
    }
};
export const checkInOutDriver = async (driver, status) => {
    try {
        let zone = await DataStore.query(CatHerd, (zone) =>
            zone.and((zone) => [zone.id.eq(driver.gsi1sk), zone.cat.eq('ZONE')])
        );
        let activeShift = getActiveShiftOld(zone[0] ?? null);
        // console.log(activeShift,zone)
        if (
            activeShift !== driver.shift &&
            activeShift !== 'All' &&
            status.toString() === '1'
        ) {
            notification.open({
                description: `Your shift has not started.\nPlease check in again when your shift starts.`,
                placement: 'bottomLeft'
            });
        } else {
            let body = {
                driver_id: driver.driver_id,
                check: status
            };
            let response = await apiRequestAxio(
                'POST',
                'pch/pch_integrations/driver_check',
                body
            );
            notification.open({
                description: response.data.message,
                placement: 'bottomLeft'
            });
        }
    } catch (e) {
        console.log(e);
        notification.open({
            description: `Making Driver ${
                status === 0 ? 'Checkout' : 'Checkin'
            } Failed`,
            placement: 'bottomLeft'
        });
    }
};

export const flagDriver = async (driver, flag, reason) => {
    try {
        let drivers = await getPaginatedData({
            variables: {
                driver_id: driver.driver_id
            },
            query: databyDriverId,
            queryName: 'databyDriverId'
        });
        // console.log(drivers)
        let dynamoPromisesArray = [];
        for (let driver of drivers) {
            dynamoPromisesArray.push(
                API.graphql({
                    query: updateCatHerd,
                    variables: {
                        input: {
                            id: driver.id,
                            cat: driver.cat,
                            flag_type: flag,
                            _version: driver._version
                        }
                    },
                    authMode: literals.API.AUTHMODE,
                    authToken:
                        store.getState().loginReducer.userData.token || 'null'
                })
            );
        }
        await Promise.all(dynamoPromisesArray);
        notification.open({
            description: flag === null ? 'Flag Removed' : 'Flag Added',
            placement: 'bottomLeft'
        });
        await logDriverActivity(
            driver,
            flag === null && driver.flag_type === 'parked'
                ? 'un_parked'
                : flag === null && driver.flag_type === 'banned'
                ? 'un_banned'
                : flag,
            reason
        );

        return true;
    } catch (e) {
        console.log(e);
        notification.open({
            description: `${
                flag === null ? 'Removing Flag Failed' : 'Added Flag Failed'
            }`,
            placement: 'bottomLeft'
        });
        return false;
    }
};

export const changeDriverDesignation = async (
    driverItem,
    dropOnItem,
    dropOn
) => {
    try {
        let _multiSelectDriver = store.getState().mainReducer.multiSelectDriver;
        store.dispatch(updateState({ multiSelectDriver: [] }));
        if (_multiSelectDriver.length === 0) {
            let values = await getCatherdTuple(driverItem.id, driverItem.cat);
            _multiSelectDriver = values ? [values] : [];
        }
        let pdPromisesArray = [];
        let dynamoPromisesArray = [];
        let carrierError = false;
        let shiftError = false;
        let targetError = false;
        let filteredDrivers = _multiSelectDriver;
        if (dropOn === 'zone' || dropOn === 'shift' || dropOn === 'board') {
            let carrierRemainDrivers = {};
            let zoneCarriers = [];
            await DataStore.query(CatHerd, (carrier) =>
                carrier.and((carrier) => [
                    carrier.classification.eq('TARGET'),
                    carrier.gsi1sk.eq(dropOnItem.id)
                ])
            ).then((values) => {
                zoneCarriers = values;
            });
            let driverTabDrivers =
                store.getState().mainReducer.driverTabDrivers;
            let drivers = [];
            Object.keys(driverTabDrivers).forEach((key) => {
                if (driverTabDrivers[key].gsi1sk === dropOnItem.id) {
                    drivers.push(driverTabDrivers[key]);
                }
            });
            for (let carrier of zoneCarriers) {
                carrierRemainDrivers[carrier.carrier_id] = {
                    AM: 0,
                    PM: 0
                };

                let amdrivers = drivers.filter(
                    (driver) =>
                        driver.shift === 'AM' &&
                        driver.carrier_id === carrier.carrier_id
                );
                let pmdrivers = drivers.filter(
                    (driver) =>
                        driver.shift === 'PM' &&
                        driver.carrier_id === carrier.carrier_id
                );

                carrierRemainDrivers[carrier.carrier_id].AM =
                    carrier.am_target - amdrivers.length;
                carrierRemainDrivers[carrier.carrier_id].PM =
                    carrier.pm_target - pmdrivers.length;
            }
            filteredDrivers = _multiSelectDriver.filter((driver) => {
                const shift =
                    dropOn === 'shift' ? dropOnItem.shift_name : driver.shift;
                if (shift === 'AM' || shift === 'PM') {
                    if (
                        Object.keys(carrierRemainDrivers).includes(
                            driver.carrier_id
                        )
                    ) {
                        if (
                            carrierRemainDrivers[driver.carrier_id][shift] > 0
                        ) {
                            carrierRemainDrivers[driver.carrier_id][shift]--;

                            return true;
                        } else {
                            targetError = true;
                            return false;
                        }
                    } else {
                        carrierError = true;
                        return false;
                    }
                } else {
                    shiftError = true;
                    return false;
                }
            });
        }
        let observeIds = [];
        for (let driver of filteredDrivers) {
            switch (dropOn) {
                // case "shift":
                //   // if (driver.shift !== dropOnItem.name) {
                //   dynamoPromisesArray.push(
                //     new Promise((resolve) => {
                //       let newdriver =
                //         dropOnItem?.z_name && dropOnItem?.name
                //           ? DataStore.save(
                //               CatHerd.copyOf(driver, (updated) => {
                //                 updated.shift = dropOnItem.name;
                //                 updated.gsi1sk = dropOnItem.z_name;
                //               })
                //             )
                //           : DataStore.save(
                //               CatHerd.copyOf(driver, (updated) => {
                //                 updated.shift = dropOnItem.name;
                //               })
                //             );
                //       resolve(newdriver);
                //     })
                //   );
                //   // }
                //   break;
                case 'board':
                case 'shift':
                case 'zone':
                    if (dropOnItem.status === true) {
                        observeIds.push(driver.id);
                        const updatedValues = {
                            id: driver.id,
                            type: `DRIVER_${dropOnItem.id}`,
                            gsi1sk: dropOnItem.id,
                            z_name: dropOnItem.z_name ?? null,
                            shift:
                                dropOn === 'shift'
                                    ? dropOnItem.shift_name
                                    : driver.shift,
                            shift_changed_time: dateConverted(
                                'CURRENT_CST_AWSDATETIME'
                            ),
                            cat: driver.cat,
                            _version: driver._version
                        };
                        dynamoPromisesArray.push(
                            API.graphql({
                                query: updateCatHerd,
                                variables: {
                                    input: updatedValues
                                },
                                authMode: literals.API.AUTHMODE,
                                authToken:
                                    store.getState().loginReducer.userData
                                        .token || 'null'
                            })
                        );
                    }
                    break;
                case 'customer':
                    // console.log("customer----", driverItem, dropOnItem, dropOn);
                    break;
                default:
                    break;
            }
        }
        store.dispatch(
            updateState({
                observeIds: [...observeIds],
                observeIdsCount: observeIds.length
            })
        );
        await Promise.allSettled(dynamoPromisesArray).then((results) => {
            // console.log("promise completed", dynamoPromisesArray);
            if (dropOn === 'shift' || dropOn === 'zone') {
                store.dispatch(
                    updateState({
                        multiSelectDriver: [],
                        checkAll: false
                    })
                );
            }

            if (dropOn === 'zone' || dropOn === 'shift' || dropOn === 'board') {
                if (results.length === _multiSelectDriver.length) {
                    results.length > 1
                        ? notification.open({
                              description: (
                                  <p>
                                      <b>{results.length}</b> Driver
                                      {results.length > 1 ? 's' : ''} assigned
                                      to <b>{dropOnItem.z_name}</b>
                                  </p>
                              ),

                              placement: 'bottomLeft'
                          })
                        : results.length > 0
                        ? notification.open({
                              description: (
                                  <p>
                                      <b>{results[0].driver_name}</b> assigned
                                      to <b>{dropOnItem.z_name}</b>
                                  </p>
                              ),
                              placement: 'bottomLeft'
                          })
                        : notification.open({
                              description: (
                                  <p>
                                      <b>{results[0].driver_name}</b> assigned
                                      to <b>{dropOnItem.z_name}</b>
                                  </p>
                              ),
                              placement: 'bottomLeft'
                          });
                } else {
                    if (
                        results.length === 0 &&
                        _multiSelectDriver.length === 1
                    ) {
                        notification.open({
                            description: (
                                <p>
                                    {shiftError
                                        ? 'Please assign driver to a shift before moving to a zone'
                                        : carrierError
                                        ? 'Driver’s Carrier is not a part of the desired zone assignment'
                                        : targetError
                                        ? 'You have reached the allowed number of drivers allowed for this customer'
                                        : 'Invalid Action'}
                                </p>
                            ),

                            placement: 'bottomLeft'
                        });
                    } else
                        notification.open({
                            description: (
                                <p>
                                    <b>
                                        {results.length}/
                                        {_multiSelectDriver.length}
                                    </b>{' '}
                                    Driver
                                    {results.length > 1 ? 's' : ''} assigned to{' '}
                                    <b>{dropOnItem.z_name}</b>
                                </p>
                            ),

                            placement: 'bottomLeft'
                        });
                }
            }

            // else {
            //   if (results.length > 0) {
            //     results.length > 1
            //       ? notification.open({
            //           description: (
            //             <p>
            //               <b>{results.length}</b> Driver
            //               {results.length > 1 ? "s" : ""} shift changed to{" "}
            //               <b>{dropOnItem.name}</b>
            //             </p>
            //           ),

            //           placement: "bottomLeft",
            //         })
            //       : notification.open({
            //           description: (
            //             <p>
            //               <b>{results[0].driver_name}'s</b> shift changed to{" "}
            //               <b>{dropOnItem.name}</b>
            //             </p>
            //           ),
            //           placement: "bottomLeft",
            //         });
            //   }
            // }
        });
        if (dropOn === 'shift') {
            for (let driver of filteredDrivers) {
                if (driver.shift !== dropOnItem.shift_name) {
                    let driverId = driver.driver_id;
                    pdPromisesArray.push(
                        new Promise((resolve) => {
                            let pdRes = apiRequestAxio(
                                'POST',
                                'pch/pch_integrations/driver_shift',
                                {
                                    driver_id: driverId,
                                    shift:
                                        dropOnItem.shift_name === 'AM'
                                            ? 1
                                            : dropOnItem.shift_name === 'PM'
                                            ? 2
                                            : 0
                                }
                            );
                            resolve(pdRes);
                        })
                    );
                }
            }

            Promise.all(pdPromisesArray).catch((e) => {
                // Handle errors here
            });
        }
    } catch (e) {
        notification.open({
            description: 'Driver status updation failed',
            placement: 'bottomLeft'
        });
    }
};

export const saveNoteToZone = async (input, action) => {
    const sucessMsg =
        action === 'read'
            ? `Marked as Read`
            : action === 'edit'
            ? `Notes Edited`
            : `Notes Added to this Zone`;
    const errorMsg =
        action === 'read'
            ? `Unable to Mark as Read`
            : action === 'edit'
            ? `Unable to edit Notes`
            : `Error in saving zone notes`;
    try {
        let res = await API.graphql({
            query: updateCatHerd,
            variables: {
                input
            },
            authMode: literals.API.AUTHMODE,
            authToken: store.getState().loginReducer.userData.token || 'null'
        });
        if (res?.data?.updateCatHerd) {
            notification.open({
                description: sucessMsg,
                placement: 'bottomLeft'
            });
            return res?.data?.updateCatHerd;
        } else {
            notification.open({
                description: errorMsg,
                placement: 'bottomLeft'
            });
        }
    } catch (e) {
        console.log('Error in saving zone notes', e);
        notification.open({
            description: errorMsg,
            placement: 'bottomLeft'
        });
    }
    return null;
};

export const addCarrier = async (id, jobs, tenant_id, tenant_name) => {
    try {
        store.dispatch(updateState({ carrierTabLoading: true }));
        let baseUrl = getAWSApiLinks('ddBackendApi');
        const options = {
            url: `${baseUrl}/carrier/add`,
            method: 'POST',
            data: {
                token: store.getState().loginReducer.userData.token || 'null',
                id,
                jobs,
                tenant_id,
                tenant_name
            }
        };
        let response = await axios(options);
        if (response.data) {
            let carriers = response.data.sort((a, b) =>
                a?.carrier_name.localeCompare(b?.carrier_name)
            );
            store.dispatch(
                updateState({ carrierTabLoading: false, targetList: carriers })
            );
            return carriers;
        }
    } catch (e) {
        console.log('error in adding carriers', e);
        store.dispatch(
            updateState({ carrierTabLoading: false, targetList: [] })
        );
        return [];
    }
};
export const getZonesAggregations = () => {
    // for tenant view
    try {
        let baseUrl = getAWSApiLinks('dynamoElasticSyncApi');
        const options = {
            url: `${baseUrl}/search`,
            method: 'POST',
            data: {
                tenant_id: store.getState().loginReducer.userData.company_id,
                token: store.getState().loginReducer.userData.token || 'null',
                action_type: 'GET_ZONE_DRIVER'
            }
        };

        axios(options).then((response) => {
            if (Object.keys(response.data).length > 0) {
                // console.log('agg', response.data)
                store.dispatch(
                    updateState({ zonesAggregation: response.data })
                );
            }
        });
    } catch (e) {
        // store.dispatch(updateState({ zonesAggregation: {} }));
        console.log(e);
    }
};
export const getZoneTargetsInfo = async () => {
    // driver stats for carrier view
    try {
        let baseUrl = getAWSApiLinks('dynamoElasticSyncApi');
        const options = {
            url: `${baseUrl}/search`,
            method: 'POST',
            data: {
                tenant_id: store.getState().loginReducer.userData.company_id,
                token: store.getState().loginReducer.userData.token || 'null',
                action_type: 'GET_CARRIER_DRIVER'
            }
        };

        return axios(options).then((response) => {
            if (Object.keys(response.data).length > 0) {
                store.dispatch(
                    updateState({ carrierTargetInfo: response.data })
                );
                return response.data;
            } else return null;
        });
    } catch (e) {
        console.log(e);
    }
};

let controllerGetTenantZoneTargetInfo;
export const getTenantZoneTargetInfo = async (firstTimeFetch = false) => {
    // get customers list for carrier view
    try {
        controllerGetTenantZoneTargetInfo?.abort();
        controllerGetTenantZoneTargetInfo = new AbortController();
        const { signal } = controllerGetTenantZoneTargetInfo;
        const selectedCustomer = store.getState().mainReducer.selectedCustomer;
        let baseUrl = getAWSApiLinks('dynamoElasticSyncApi');
        const response = await axios.post(
            `${baseUrl}/search`,
            {
                token: store.getState().loginReducer.userData.token || 'null',
                action_type: 'GET_LIST_OF_CUSTOMERS',
                carrier_id: store.getState().loginReducer.userData.company_id
            },
            { signal }
        );

        if (response?.data?.customers) {
            store.dispatch(updateState({ customers: response.data.customers }));
            if (
                response.data.customers.length > 0 &&
                selectedCustomer === null &&
                firstTimeFetch
            ) {
                store.dispatch(
                    updateState({
                        selectedCustomer: response.data.customers[0].id
                    })
                );
            }
        }
    } catch (e) {
        if (e.code !== 'ERR_CANCELED') {
            console.log(e);
        }
    }
};

export const getCompanyUsers = async () => {
    try {
        const res = await apiRequestAxio(
            'Get',
            `pch/pch_listings/company_users?company_id=${
                store.getState().loginReducer.userData.company_id
            }`
        );
        if (res?.data.status) {
            return res.data.data;
        } else return [];
    } catch (e) {
        console.log(e);
    }
};

export const updateUnassignedModalDriver = (drivers) => {
    const modalDrivers = store.getState().mainReducer.modalDrivers;
    let userData = store.getState()?.loginReducer?.userData;
    if (userData !== null) {
        Object.keys(drivers).map((key) => {
            let newDriver = drivers[key];
            if (
                (newDriver?.dd_tenant ===
                    `TENANT#${
                        store.getState().loginReducer.userData.company_id
                    }` &&
                    newDriver?.triggerType === 'ASSIGNED') ||
                newDriver?._deleted === true
            ) {
                //remove driver from Assign Modal
                delete modalDrivers[`${newDriver?.id}_${newDriver?.cat}`];
                store.dispatch(
                    updateState({ modalDrivers: { ...modalDrivers } })
                );
            } else if (
                (newDriver?.triggerType === null &&
                    newDriver?.dd_tenant === 'TENANT#') ||
                newDriver?.triggerType === 'NA'
            ) {
                //add driver in Asssign Modal
                if (
                    modalDrivers.hasOwnProperty(
                        `${newDriver?.id}_${newDriver?.cat}`
                    )
                ) {
                    if (
                        parseInt(
                            modalDrivers[`${newDriver?.id}_${newDriver?.cat}`]
                                ._version
                        ) < parseInt(newDriver._version)
                    ) {
                        modalDrivers[`${newDriver?.id}_${newDriver?.cat}`] = {
                            ...newDriver,
                            key: `${newDriver.id}_${newDriver.cat}`
                        };
                    }
                } else
                    modalDrivers[`${newDriver?.id}_${newDriver?.cat}`] = {
                        ...newDriver,
                        key: `${newDriver.id}_${newDriver.cat}`
                    };
                //remove from driver tab
            }
            return key;
        });
        if (Object.keys(drivers).length) {
            store.dispatch(
                updateState({
                    modalDrivers: { ...modalDrivers }
                })
            );
        }
    }
};
export const getAllCarrier = async (zoneId) => {
    try {
        // console.log(zoneId, Array.isArray(zoneId))
        let userData = store.getState()?.loginReducer?.userData;

        if (zoneId !== 'All' && zoneId !== null && zoneId !== 'None') {
            if (Array.isArray(zoneId)) {
                let criteria = (ct) =>
                    ct.and((ct) => [
                        ct.classification.eq('TARGET'),
                        ct.tenant_id.eq(userData.company_id),
                        ct.or((ct) =>
                            zoneId.map((zone) => ct.gsi1sk.eq(zone.id), ct)
                        )
                    ]);
                let res = await DataStore.query(CatHerd, criteria);
                // console.log('data', data)
                let data = res.reduce((unique, current) => {
                    if (
                        !unique.some(
                            (obj) => obj.carrier_id === current.carrier_id
                        )
                    ) {
                        unique.push(current);
                    }
                    return unique;
                }, []);
                store.dispatch(
                    updateState({
                        driverCarrierOption: [...data]
                    })
                );
                return [...data];
            } else {
                const data = await DataStore.query(CatHerd, (target) =>
                    target.and((target) => [
                        target.classification.eq('TARGET'),
                        target.gsi1sk.eq(zoneId)
                    ])
                );
                store.dispatch(
                    updateState({
                        driverCarrierOption: [...data]
                    })
                );
                return [...data];
            }
        } else {
            const res = await apiRequestAxio(
                'GET',
                `pch/pch_sync/carrier_listing?tenant_id=[${userData.company_id}]`,
                {}
            );
            store.dispatch(
                updateState({
                    driverCarrierOption: [...res?.data?.data]
                })
            );
            return [...res?.data?.data];
        }
    } catch (e) {
        console.log('error in fetching carriers');
    }
};

export const getDashboardZoneInfo = async (zoneId, shift) => {
    try {
        let userData = store.getState()?.loginReducer?.userData;

        let baseUrl = getAWSApiLinks('dynamoElasticSyncApi');
        const options = {
            url: `${baseUrl}/search`,
            method: 'POST',
            data: {
                token: store.getState().loginReducer.userData.token || 'null',
                action_type: 'GET_TRUCKING_STATUS',
                zoneId: zoneId,
                shift: shift,
                tenant_id: userData.company_id
            }
        };

        let response = await axios(options);
        return response.data;
    } catch (e) {
        console.log(e);
        return [];
    }
};

export const getJobOrderOption = async (jobId, optionType, extraParam = '') => {
    try {
        let userData = store.getState()?.loginReducer?.userData;
        let res = await apiRequestAxio(
            'GET',
            `pch/pch_load/${optionType}/${jobId}${extraParam}`,
            {},
            {
                token: userData.token,
                'pch-app-token': process.env.REACT_APP_PCH_APP_TOKEN,
                'X-API-KEY': process.env.REACT_APP_X_API_KEY
            }
        );
        // console.log(res)
        return res?.data?.data;
    } catch (e) {
        console.log(e);
    }
};

export const orderLoads = async (body) => {
    try {
        let userData = store.getState()?.loginReducer?.userData;
        let res = await apiRequestAxio('POST', `pch/pch_load/create`, body, {
            token: userData.token,
            'pch-app-token': process.env.REACT_APP_PCH_APP_TOKEN,
            'X-API-KEY': process.env.REACT_APP_X_API_KEY
        });
        // console.log(res)
        return res?.data?.data;
    } catch (e) {
        console.log(e);
    }
};

export const purchaseOrderAllocation = async ({
    job_id,
    purchase_order_linked_id
}) => {
    try {
        let userData = store.getState()?.loginReducer?.userData;
        let res = await apiRequestAxio(
            'GET',
            `pch/pch_load/purchase_order_allocation?job_id=${job_id}&purchase_order_linked_id=${purchase_order_linked_id}`,
            {},
            {
                token: userData.token,
                'pch-app-token': process.env.REACT_APP_PCH_APP_TOKEN,
                'X-API-KEY': process.env.REACT_APP_X_API_KEY
            }
        );
        // console.log(res)
        return res?.data?.data ?? [];
    } catch (e) {
        console.log(e);
        return [];
    }
};
let controllerGetZonesByTenant;
export const getZonesByTenant = async (
    carrierId,
    tenantId,
    tenantZones,
    zoneCase,
    stats
) => {
    //for carrier view
    try {
        if (zoneCase === 'deleted') {
            const prevzones = store.getState()?.mainReducer?.zones;
            const selectedCustomer =
                store.getState()?.mainReducer?.selectedCustomer;

            if (selectedCustomer === tenantId && zoneCase === 'deleted') {
                let newZones = prevzones.filter(
                    (zone) => zone.id !== tenantZones[0]
                );

                if (newZones.length === 0) {
                    store.dispatch(
                        updateState({
                            selectedCustomer: null,
                            selectedBoardZone: null,
                            zones: newZones
                        })
                    );
                } else {
                    store.dispatch(
                        updateState({
                            zones: newZones,
                            selectedBoardZone: null
                        })
                    );
                }
            }
            return;
        }
        controllerGetZonesByTenant?.abort();
        controllerGetZonesByTenant = new AbortController();
        const { signal } = controllerGetZonesByTenant;
        let baseUrl = getAWSApiLinks('dynamoElasticSyncApi');
        const response = await axios.post(
            `${baseUrl}/search`,
            {
                action_type: 'BOARD_TAB_CARRIER_VIEW_ZONE_STATS',
                tenant_id: tenantId,
                zoneIds: tenantZones,
                carrier_id: carrierId,
                stats,
                token: store.getState().loginReducer.userData.token || 'null'
            },
            { signal }
        );

        if (response?.data) {
            const zonesAggregation =
                store.getState()?.mainReducer?.zonesAggregation;
            switch (zoneCase) {
                case 'all':
                    const zones = Object.values(response.data);
                    if (stats) {
                        store.dispatch(
                            updateState({ zonesAggregation: response.data })
                        );
                    }
                    store.dispatch(
                        updateState({ zones, zonesSideLoader: false })
                    );
                    return zones;
                case 'update_zone_aggregation':
                    if (tenantZones.length > 0) {
                        const zoneitem = response.data[tenantZones[0]];
                        if (zonesAggregation?.[zoneitem.id])
                            store.dispatch(
                                updateState({
                                    zonesAggregation: {
                                        ...zonesAggregation,
                                        [zoneitem.id]: zoneitem
                                    }
                                })
                            );
                    }
                    break;
                case 'specific': // add new zone
                    const oldzones = store.getState()?.mainReducer?.zones;
                    let zone = response.data[tenantZones[0]];
                    store.dispatch(
                        updateState({
                            zones: [...oldzones, zone],
                            zonesSideLoader: false
                        })
                    );
                    store.dispatch(
                        updateState({
                            zonesAggregation: {
                                ...zonesAggregation,
                                [zone.id]: zone
                            }
                        })
                    );
                    break;
                default: // deleted
                    const selectedBoardZone =
                        store.getState()?.mainReducer?.selectedBoardZone;
                    const prevzones = store.getState()?.mainReducer?.zones;

                    if (
                        selectedBoardZone === tenantZones[0] &&
                        zoneCase === 'deleted'
                    ) {
                        let newZones = prevzones.filter(
                            (zone) => zone.id !== tenantZones[0]
                        );
                        store.dispatch(
                            updateState({
                                zones: newZones,
                                selectedBoardZone: null
                            })
                        );
                        if (newZones.length === 0) {
                            store.dispatch(
                                updateState({
                                    selectedCustomer: null,
                                    selectedBoardZone: null,
                                    customers: []
                                })
                            );
                        }
                    } else
                        store.dispatch(
                            updateState({
                                zones: prevzones.filter(
                                    (zone) => zone.id !== tenantZones[0]
                                )
                            })
                        );
                    break;
            }
        }
        return response?.data ?? null;
    } catch (e) {
        if (e.code !== 'ERR_CANCELED') {
            store.dispatch(updateState({ zonesSideLoader: false, zones: [] }));
            console.log(e);
        }
        return null;
    }
};
