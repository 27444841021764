import { generateClient } from "aws-amplify/api";
import store from "../store/store.js";
import moment from "moment-timezone";
import {
  dataByCarrierAndCat,
  dataByZone,
  getCatHerd,
  logByDriverId,
} from "../graphql/queries";
import { createActivityLog, updateCatHerd } from "../graphql/mutations";
import { dateConverted } from "../utils/date";
import { v4 as uuidv4 } from "uuid";
import { projectLiterals as literals } from "../utils/projectLiterals.js";

const API = generateClient();

export const getPaginatedData = async ({
  messageNextToken = null,
  resData = [],
  variables,
  query,
  queryName,
}) => {
  try {
    let userData = store.getState()?.loginReducer?.userData;
    const { data } = await API.graphql({
      query: query,
      variables: {
        ...variables,
        nextToken: messageNextToken,
      },
      authMode: literals.API.AUTHMODE,
      authToken: userData.token || "null",
    });
    if (data[queryName]?.items) {
      resData = [...resData, ...data[queryName]?.items];
    }
    if (data[queryName]?.nextToken) {
      return await getPaginatedData({
        messageNextToken: data[queryName]?.nextToken,
        resData,
        variables,
        query,
        queryName,
      });
    } else {
      return resData;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getDriverActivity = async (driver, token = null, action) => {
  try {
    let limit = 50;
    moment.tz.setDefault("America/Chicago");
    // const currentDate = moment();
    // const sevenDaysAgo = currentDate.subtract(7, 'days');
    // const formattedDate = sevenDaysAgo.format('YYYY-MM-DD'); // Change the format as needed
    let variables = {
      driver_id: driver.driver_id,
      // skey: {
      //     ge: `${formattedDate} 00:00:00.000`
      // },
      sortDirection: "DESC",
      limit: limit,
    };
    let activities = await getPaginatedLimitedData({
      variables: variables,
      query: logByDriverId,
      queryName: "logByDriverId",
      limit: limit,
      messageNextToken: token,
      filter: action,
    });
    return activities;
  } catch (e) {
    return [];
  }
};

export const getPaginatedLimitedData = async ({
  messageNextToken = null,
  resData = [],
  variables,
  query,
  queryName,
  limit,
  filter = "All",
}) => {
  try {
    let userData = store.getState()?.loginReducer?.userData;
    const { data } = await API.graphql({
      query: query,
      variables: {
        ...variables,
        nextToken: messageNextToken,
      },
      authMode: literals.API.AUTHMODE,
      authToken: userData.token || "null",
    });
    if (data[queryName]?.items) {
      if (filter !== "All") {
        resData = [
          ...resData,
          ...data[queryName]?.items.filter(
            (item) => item.actionType === filter
          ),
        ];
      } else {
        resData = [...resData, ...data[queryName]?.items];
      }
    }
    if (data[queryName]?.nextToken && resData.length < limit) {
      return await getPaginatedLimitedData({
        messageNextToken: data[queryName]?.nextToken,
        resData,
        variables,
        query,
        queryName,
        limit,
        filter,
      });
    } else {
      return { items: resData, nextToken: data[queryName]?.nextToken };
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const logDriverActivity = async (driver, actionType, reason = "") => {
  let userData = store.getState()?.loginReducer?.userData;
  try {
    await API.graphql({
      query: createActivityLog,
      variables: {
        input: {
          id: `DRIVER#${driver.driver_id}_${uuidv4()}`,
          skey: dateConverted("CURRENT_CST_STRING_FORMAT"),
          type: "DRIVER",
          actionType: actionType,
          platform: "DD",
          user_name: `${userData.first_name} ${userData.last_name}`,
          user_id: userData.id,
          reason: reason,
          driver_tenant: `DRIVER#`,
          driver_id: driver.driver_id,
        },
      },
      authMode: literals.API.AUTHMODE,
      authToken: userData.token || "null",
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateCatherdTuple = async (inputs) => {
  try {
    let response = await API.graphql({
      query: updateCatHerd,
      variables: {
        input: inputs,
      },
      authMode: literals.API.AUTHMODE,
      authToken: store.getState().loginReducer.userData.token || "null",
    });
    if (response.data) {
      return response.data.updateCatHerd;
    }
    return null;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getCatherdTuple = async (id, cat) => {
  try {
    let response = await API.graphql({
      query: getCatHerd,
      variables: {
        id: id,
        cat: cat,
      },
      authMode: literals.API.AUTHMODE,
      authToken: store.getState().loginReducer.userData.token || "null",
    });
    if (response.data) {
      return response?.data?.getCatHerd ?? null;
    }
    return null;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getTupleByZoneAndClassification = async (
  zoneId,
  classification
) => {
  try {
    let record = await getPaginatedData({
      variables: {
        classification: classification,
        gsi1sk: zoneId,
      },
      query: dataByZone,
      queryName: "dataByZone",
    });
    return record;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getTargetCarrierbyCategory = async (carrierId, catId) => {
  try {
    let response = await API.graphql({
      query: dataByCarrierAndCat,
      variables: {
        carrier_id: carrierId,
        cat: { beginsWith: `CT#${catId}` },
      },
      authMode: literals.API.AUTHMODE,
      authToken: store.getState().loginReducer.userData.token || "null",
    });
    console.log("response: ", response);
    if (response.data) {
      return response?.data?.dataByCarrierAndCat ?? null;
    }
    return null;
  } catch (e) {
    console.log(e);
    return null;
  }
};
