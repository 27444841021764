export const projectLiterals = {
  ENVIROMENTS: {
    DEV: "DEV",
    PROD: "PROD",
    STAGE: "STAGE",
  },
  JITSU: {
    HOSTURL: "https://clkchhoan0003356m2c0rcqiz.d.jitsu.com",
  },
  TAGMANAGER: {
    GTMID: "G-6DM57BBHFY",
    PLATFORM: "react",
  },
  API: {
    AUTHMODE: "lambda",
    PARAMS: {
      TERMINALS: "terminals",
      PRODUCTS: "products",
      POS: "pos",
      CARRIERS: "carriers",
    },
  },
  DATASTORE: {
    EVENT: {
      READY: "ready",
      NETWORKSTATUS: "networkStatus",
    },
    OPTYPE: {
      CREATE: "CREATE",
      UPDATE: "UPDATE",
      DELETE: "DELETE",
      INSERT: "INSERT",
    },
    CLASSIFICATION: {
      ZONE: "ZONE",
      DRIVER: "DRIVER",
      LOAD: "LOAD",
      JOB: "JOB",
      TARGET: "TARGET",
      DISTRICT: "DISTRICT",
      CREW: "CREW",
      BASIN: "BASIN",
    },
    VALUES:{
      TENANT: "TENANT",
      ASSIGNED: "ASSIGNED",
      NA: "NA",
    }
  },
  THEME: {
    DARK: "dark",
    LIGHT: "light",
  },
  NAVLINKS: {
    ZONES: "/zones",
  },
  VIEWS: {
    SKELETON: {
      LOAD: "load",
      USER: "user",
      ZONE: "zone",
    },
  },
  MENUITEMS: {
    LABELS: {
      ORDER_LOAD: "Order a Load",
      ORDER_ASSET: "Order an Asset",
    },
    KEYS: {
      ASSET: "asset",
      LOAD: "load",
    },
  },
  TOOLTIP: {
    CREATE_LOAD: "Create Load",
    CREATE_ASSET: "Create Asset",
    LOAD_ASSIGNED: "Load Assigned",
    CHECK_IN: "Checked in, Ready for load",
    CHECK_OUT: "Checked out, Off duty",
    NO_SHOW: "This driver has not been seen for more than 72 hours.",
    UNKOWN: "Unkown",
  },
  TEXT: {
    UNKOWN: "Unkown",
  },
  BUTTONTEXT: {},
  OPTIONTYPE: {
    JOBS: "jobs",
  },
  PLACEHOLDER: {
    // order load modal
    ORDER_LOAD_SELECT_JOB: "Select Job",
    ORDER_LOAD_SELECT_PO: "Select PO",
    ORDER_LOAD_SELECT_TERMINAL: "Select Terminal",
    ORDER_LOAD_SELECT_PRODUCT: "Select Product",
    ORDER_LOAD_SELECT_CARRIER: "Select Carrier",
    ORDER_LOAD_NO: "# of Loads",
    ORDER_LOAD_STAGE: "Stage",
  },
  MESSAGE: {
    JOB: "Please select Job",
    PO: "Please select PO",
    TERMINAL: "Please select Terminal",
    PRODUCT: "Please select Product",
    CARRIER: "Please select Carrier",
    LOAD_NO: "Invalid load numbers must be a number with max 2 digits",
    STAGE: "Missing stage",
    IMPORT_DRIVER_UNMATCHZONE:
      "The zone name you are trying to assign a driver did not find a match. Unmatched Zone(s):",
    IMPORT_DRIVER_UNRECOGNIZED_ZONE:
      "The shift you are trying to assign this driver is not recognized",
    IMPORT_DRIVER_FORMAT:
      "The import is not successful. Please check the source file for formatting issues",
    IMPORT_DRIVER_EMPTY: "Uploaded file is empty.",
    IMPORT_FILE_FORMAT: "The file format is not supported.",
    IMPORT_SUCCESS: "The imported spreadsheet successfully added all drivers",
    IMPORT_PARTIAL_SUCCESS:
      "The imported spreadsheet successfully added drivers, but some were not added. Please see the below sheet for details",
    ADD_ZONE_FAILED: "Unable to create zone, try again later",
    NETWORK_ERROR: "Please check your Network connection",
    DRIVER_AVAILABLE: "Driver marked as Available",
    DRIVER_UNAVAILABLE: "Driver marked as Unavailable",
    DRIVER_AVAILABLE_FAILED: "Unable to mark driver as Available",
    DRIVER_UNAVAILABLE_FAILED: "Unable to mark driver as Unavailable",
  },
};
