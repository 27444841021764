import {createStore} from "redux";
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { rootReducer } from "./reducers/rootReducer";
import {initialLoginState} from './reducers/loginReducer'
const migrations = {
    3: (state) => {
        // migration clear out device state
        // console.log('0',state)
        return {
          ...state,
          loginReducer: undefined   
        }
      },
    1: (state) => {
    // migration to keep only device state
        // console.log('1',state)
        return {
            loginReducer: initialLoginState
        }
    }
};
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['loginReducer'],
    version: 3,
    migrate: createMigrate(migrations, { debug: true }),
}
   
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer)
export const persistor = persistStore(store)
export default store;
