import { Row, Col, Progress } from "antd";

export const BulkprogressNotification = ({ total, remaining }) => {
  const textStyle = {
    display: "flex",
    alignItems: "center",
  };
  return (
    <div>
      <Row>
        <Col span={4}>
          <Progress
            type="circle"
            width={40}
            percent={(((total - remaining) / total) * 100).toFixed()}
          />
        </Col>
        <Col span={20} style={textStyle}>
          {remaining > 0 ? (
            <span>Synching in Progress</span>
          ) : (
            <span>Synched Successfully</span>
          )}
        </Col>
      </Row>
    </div>
  );
};
