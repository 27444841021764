import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import {JitsuProvider} from "@jitsu/jitsu-react";
import {projectLiterals as literals} from "./utils/projectLiterals";
//to hide all console.logs in production
if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === literals.ENVIROMENTS.PROD) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
      <JitsuProvider options={{host: literals.JITSU.HOSTURL}}>
        <App />
      </JitsuProvider>
      </Provider>
    </DndProvider>
  // </React.StrictMode>,
);

// serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
