export const UPADTE_STATE = 'UPADTE_STATE'
export const ADD_ZONE = 'ADD_ZONE'
export const REMOVE_ZONE = 'REMOVE_ZONE'
export const REMOVE_DRIVER = 'REMOVE_DRIVER'

export function updateState(payload) {
    return { type: UPADTE_STATE, payload };
}

export function addZone(payload) {
    return { type: ADD_ZONE, payload };
}

export function removeZone(payload) {
    return { type: REMOVE_ZONE, payload };
}

export function removeDriver(payload) {
    return { type: REMOVE_DRIVER, payload };
}
