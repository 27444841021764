import { LOGIN_USER, LOGOUT_USER } from "../actions/login_actions";
export const initialLoginState = {
  isUserLogin:false,
  cognitoUser: null,
  userData: null,
  userType:{
    carrier: ["2"],
    tenant: ["1","3","4"]
  }
}
const LoginState = initialLoginState;
let newState = {};
export default function loginReducer(state = LoginState, action) {
  switch (action.type) {
    case LOGIN_USER:
      newState = {};
      for (let key in action.payload) {
        newState = Object.assign(state, { [key]: action.payload[key] });
      }
      return { ...state, ...newState };
    case LOGOUT_USER:
      return { 
        isUserLogin:false,
        cognitoUser: null,
        userData: null,
        userType: {
          carrier: ["2"],
          tenant: ["1","3","4"]
        }
      };
    default:
      return state;
  }
}
