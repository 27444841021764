/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCatHerd = /* GraphQL */ `
  query GetCatHerd($id: ID!, $cat: String!) {
    getCatHerd(id: $id, cat: $cat) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCatHerds = /* GraphQL */ `
  query ListCatHerds(
    $id: ID
    $cat: ModelStringKeyConditionInput
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCatHerds(
      id: $id
      cat: $cat
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCatHerds = /* GraphQL */ `
  query SyncCatHerds(
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCatHerds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGlobalLookups = /* GraphQL */ `
  query GetGlobalLookups($id: ID!, $cat: String!) {
    getGlobalLookups(id: $id, cat: $cat) {
      id
      cat
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGlobalLookups = /* GraphQL */ `
  query ListGlobalLookups(
    $id: ID
    $cat: ModelStringKeyConditionInput
    $filter: ModelGlobalLookupsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGlobalLookups(
      id: $id
      cat: $cat
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cat
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGlobalLookups = /* GraphQL */ `
  query SyncGlobalLookups(
    $filter: ModelGlobalLookupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGlobalLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cat
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReports = /* GraphQL */ `
  query GetReports($id: ID!, $type: String!) {
    getReports(id: $id, type: $type) {
      id
      type
      zone_name
      driver_id
      driver_name
      description
      report_status
      carrier_id
      carrier_name
      tenant_id
      DD_current_load
      DD_current_load_status
      PD_current_load
      PD_current_load_status
      DD_check_in
      PD_check_in
      DD_driver_available
      PD_driver_available
      DD_ready_for_load_time
      PD_ready_for_load_time
      DD_last_load_no
      PD_last_load_no
      DD_last_job_name
      PD_last_job_name
      PD_last_delivered_date
      DD_last_delivered_date
      zone_shift
      DD_driver_shift
      PD_driver_shift
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $id: ID
    $type: ModelStringKeyConditionInput
    $filter: ModelReportsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReports(
      id: $id
      type: $type
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        zone_name
        driver_id
        driver_name
        description
        report_status
        carrier_id
        carrier_name
        tenant_id
        DD_current_load
        DD_current_load_status
        PD_current_load
        PD_current_load_status
        DD_check_in
        PD_check_in
        DD_driver_available
        PD_driver_available
        DD_ready_for_load_time
        PD_ready_for_load_time
        DD_last_load_no
        PD_last_load_no
        DD_last_job_name
        PD_last_job_name
        PD_last_delivered_date
        DD_last_delivered_date
        zone_shift
        DD_driver_shift
        PD_driver_shift
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReports = /* GraphQL */ `
  query SyncReports(
    $filter: ModelReportsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        zone_name
        driver_id
        driver_name
        description
        report_status
        carrier_id
        carrier_name
        tenant_id
        DD_current_load
        DD_current_load_status
        PD_current_load
        PD_current_load_status
        DD_check_in
        PD_check_in
        DD_driver_available
        PD_driver_available
        DD_ready_for_load_time
        PD_ready_for_load_time
        DD_last_load_no
        PD_last_load_no
        DD_last_job_name
        PD_last_job_name
        PD_last_delivered_date
        DD_last_delivered_date
        zone_shift
        DD_driver_shift
        PD_driver_shift
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getActivityLog = /* GraphQL */ `
  query GetActivityLog($id: ID!, $skey: String!) {
    getActivityLog(id: $id, skey: $skey) {
      id
      skey
      type
      actionType
      platform
      user_name
      user_id
      reason
      driver_tenant
      driver_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listActivityLogs = /* GraphQL */ `
  query ListActivityLogs(
    $id: ID
    $skey: ModelStringKeyConditionInput
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActivityLogs(
      id: $id
      skey: $skey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        skey
        type
        actionType
        platform
        user_name
        user_id
        reason
        driver_tenant
        driver_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncActivityLogs = /* GraphQL */ `
  query SyncActivityLogs(
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActivityLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        skey
        type
        actionType
        platform
        user_name
        user_id
        reason
        driver_tenant
        driver_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dataByCategory = /* GraphQL */ `
  query DataByCategory(
    $cat: String!
    $gsi1sk: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataByCategory(
      cat: $cat
      gsi1sk: $gsi1sk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dataByZone = /* GraphQL */ `
  query DataByZone(
    $gsi1sk: String!
    $cat: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataByZone(
      gsi1sk: $gsi1sk
      cat: $cat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dataByZoneLineUp = /* GraphQL */ `
  query DataByZoneLineUp(
    $gsi1sk: String!
    $line_up_order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataByZoneLineUp(
      gsi1sk: $gsi1sk
      line_up_order: $line_up_order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dataByTenant = /* GraphQL */ `
  query DataByTenant(
    $tenant_id: String!
    $cat: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataByTenant(
      tenant_id: $tenant_id
      cat: $cat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dataByTenantClass = /* GraphQL */ `
  query DataByTenantClass(
    $tenant_id: String!
    $classification: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataByTenantClass(
      tenant_id: $tenant_id
      classification: $classification
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dataByDDTenant = /* GraphQL */ `
  query DataByDDTenant(
    $dd_tenant: String!
    $cat: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataByDDTenant(
      dd_tenant: $dd_tenant
      cat: $cat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const allLoadsById = /* GraphQL */ `
  query AllLoadsById(
    $load_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allLoadsById(
      load_id: $load_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const loadByJobId = /* GraphQL */ `
  query LoadByJobId(
    $job_id: String!
    $cat: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loadByJobId(
      job_id: $job_id
      cat: $cat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const allJobsByJobId = /* GraphQL */ `
  query AllJobsByJobId(
    $job_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allJobsByJobId(
      job_id: $job_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const databyDriverId = /* GraphQL */ `
  query DatabyDriverId(
    $driver_id: String!
    $cat: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    databyDriverId(
      driver_id: $driver_id
      cat: $cat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const databyClassification = /* GraphQL */ `
  query DatabyClassification(
    $classification: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    databyClassification(
      classification: $classification
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCatHerdByClassification = /* GraphQL */ `
  query GetCatHerdByClassification(
    $classification: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCatHerdByClassification(
      classification: $classification
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCatHerdByClassificationAndGS1SK = /* GraphQL */ `
  query GetCatHerdByClassificationAndGS1SK(
    $classification: String!
    $gsi1sk: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCatHerdByClassificationAndGS1SK(
      classification: $classification
      gsi1sk: $gsi1sk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const databyCarrier = /* GraphQL */ `
  query DatabyCarrier(
    $carrier_id: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    databyCarrier(
      carrier_id: $carrier_id
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dataByCarrierAndClass = /* GraphQL */ `
  query DataByCarrierAndClass(
    $carrier_id: String!
    $classification: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataByCarrierAndClass(
      carrier_id: $carrier_id
      classification: $classification
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dataByCarrierAndCat = /* GraphQL */ `
  query DataByCarrierAndCat(
    $carrier_id: String!
    $cat: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataByCarrierAndCat(
      carrier_id: $carrier_id
      cat: $cat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cat
        type
        gsi1sk
        tenant_id
        dd_tenant
        tenant_name
        tenant_type
        z_name
        status
        terminal
        district
        carrier
        basin
        crew
        jobs
        loads
        drivers
        is_assigned
        assign_load_status
        assigned_load_ids
        am_shift_start_time
        am_shift_buffer_time
        pm_shift_start_time
        pm_shift_buffer_time
        cc_email
        cc_supervisor_email
        am_alert_time
        pm_alert_time
        am_supervisor_time
        pm_supervisor_time
        z_notes
        note_read_by
        load_name
        load_no
        load_id
        job_id
        job_type
        is_appt_time_required
        is_stage_required
        linked_terminal_id
        terminal_id
        terminal_name
        linked_product_id
        product_id
        product_name
        stage
        assigned_driver
        load_status
        load_created_at
        carrier_status
        operator_id
        operator_name
        product_inventory {
          product_name
          on_site_inventory
          __typename
        }
        box_inventory {
          product_name
          loaded_boxes
          partial_empty_boxes
          empty_boxes
          __typename
        }
        job_name
        load_ids
        district_id
        district_name
        crew_id
        crew_name
        basin_id
        basin_name
        linked_carrier {
          carrier_id
          carrier_name
          carrier_linked_id
          carrier_status
          carrier_linked_status
          __typename
        }
        job_status
        working_status
        progress_status
        active_trucks
        avg_total_time
        avg_travel_time
        avg_transition_time
        driver_name
        driver_id
        classification
        phone_no
        line_up_order
        email
        on_duty
        driver_carrier_tenant
        shift
        carrier_id
        carrier_name
        accepted_load_id
        assigned_boxes_new
        last_delivered_date
        last_load_job
        last_load_delivered
        check_in
        checked_time
        accepted_on_during_checked_in
        trailer_number
        truck_number
        user_id
        turns_per_shift
        no_of_load_delivered_in_current_shift
        driver_status
        driver_linked_status
        avatar
        no_of_assigned_loads
        active_load
        driver_imported_at
        import_tag
        shift_changed_time
        driver_flagged
        flag_type
        agg_drivers
        agg_active_drivers
        agg_active_loads
        am_target
        pm_target
        shift_alert_email {
          id
          name
          email
          __typename
        }
        shift_alert_sms {
          id
          name
          phone_no
          __typename
        }
        custom_contact {
          id
          reference
          type
          value
          __typename
        }
        triggerType
        avg_pump_time
        last_stage_pumped {
          stage_no
          well
          pump_time
          avg_box_time
          transition_time
          total_boxes
          total_pumped
          __typename
        }
        product_details {
          pumped
          boxes_cashed
          product_name
          __typename
        }
        last_update_trucking
        last_update_well_site
        last_update_stage_pumped
        last_update_inventory
        total_drivers
        success_drivers
        fail_drivers
        orignal_file
        exception_file
        uploaded_at
        uploaded_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const logByDriverId = /* GraphQL */ `
  query LogByDriverId(
    $driver_id: String!
    $skey: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logByDriverId(
      driver_id: $driver_id
      skey: $skey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        skey
        type
        actionType
        platform
        user_name
        user_id
        reason
        driver_tenant
        driver_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
