import { useDrag, useDrop } from "react-dnd";
import { memo, useMemo } from "react";
import {
  List,
  Avatar,
  Col,
  Row,
  Collapse,
  Divider,
  Dropdown,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../store/actions/main_actions";
import React, { useState, useEffect } from "react";
import info from "../../images/info.svg";
import dots from "../../images/three-dots.png";
import { getEmptyImage } from "react-dnd-html5-backend";
import { changeDriverDesignation } from "../../services/apiCalls";
import DriverMenu from "../../Components/Menus/DriverMenu";
import DriverImage from "../../Components/DriverComponents/DriverAvatar";
import StatusTooltip from "../../Components/DriverComponents/DriverStatusTooltip";
import DriverTags from "../../Components/DriverComponents/DriverTags";
import DriverExpandInfo from "../../Components/DriverComponents/DriverExpandInfo";

const { Panel } = Collapse;

const DriversList = ({
  checkAll,
  index,
  driver,
  isOverTarget,
  previewList,
  collapseAllDrivers,
  style,
  rerenderList,
  setIndexCollapse,
  expandDrivers,
}) => {
  const dispatch = useDispatch();
  const zones = useSelector((state) => state.mainReducer.zones);
  const multiSelectDriver = useSelector(
    (state) => state.mainReducer.multiSelectDriver
  );
  const userData = useSelector((state) => state.loginReducer.userData);
  const userType = useSelector((state) => state.loginReducer.userType);

  const [checkIt, setCheckIt] = useState(
    multiSelectDriver.indexOf(driver) !== -1 ? true : false
  );
  const [expand, setExpand] = useState(collapseAllDrivers);
  function attachRef(el) {
    drag(el);
    drop(el);
  }

  useEffect(() => {
    setExpand(collapseAllDrivers);
  }, [collapseAllDrivers]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "job",
      drop: () => ({ ...driver, index: index }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [driver]
  );

  const changeDriverShift = async (driverItem, dropOnItem, dropOn) => {
    changeDriverDesignation(driverItem, dropOnItem, dropOn);
  };
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "driver",
      item: { ...driver },
      end: (seletedItem, monitor) => {
        const dropResult = monitor.getDropResult();
        if (seletedItem && dropResult?.ShiftsData) {
          changeDriverShift(seletedItem, dropResult.ShiftsData, "shift");
        } else if (seletedItem && dropResult?.zone) {
          changeDriverShift(seletedItem, dropResult.zone, "zone");
        } else if (seletedItem && dropResult?.customer) {
          changeDriverShift(seletedItem, dropResult.customer, "customer");
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [driver]
  );
  preview(getEmptyImage(), { captureDraggingState: true });
  let opacity = isDragging; //&& multiSelectDriver.length <= 1 ? 0.3 : 1;
  let border1 = "";
  let border = isDragging; //&& multiSelectDriver.length <= 1 ? "2px dashed #d2d4db" : "";
  let borderRadius = "";
  const isActive = canDrop && isOver;
  let backgroundColor = "white";
  let marginLeft = "";
  if (isActive) {
    backgroundColor = "rgb(9, 11, 17)";
    border1 = "1px solid #093254";
    borderRadius = "4px";
    marginLeft = "8px";
  } else if (canDrop) {
    border1 = "1px solid #093254";
    borderRadius = "4px";
    marginLeft = "8px";
  }

  let dragStyle = {
    cursor: "grabbing",
    opacity,
    backgroundColor,
    border,
    border1,
    borderRadius,
    marginLeft,
  };

  const onCheckDriver = () => {
    setCheckIt(!checkIt);
    if (!checkAll && multiSelectDriver.indexOf(driver) === -1) {
      multiSelectDriver.push(driver);
      dispatch(updateState({ multiSelectDriver: [...multiSelectDriver] }));
    } else {
      const index = multiSelectDriver.indexOf(driver);
      if (index > -1) {
        multiSelectDriver.splice(index, 1);
      }
      dispatch(
        updateState({
          multiSelectDriver: [...multiSelectDriver],
          checkAll: false,
        })
      );
    }
  };

  const driverListContent = useMemo(() => {
    if (driver) {
      return (
        <Collapse
          collapsible="icon"
          accordion
          bordered={false}
          ghost
          expandIconPosition="end"
          onChange={() => {
            setExpand(!expand);
            setIndexCollapse(index, !expand);
            rerenderList();
          }}
          activeKey={
            expandDrivers
              ? !collapseAllDrivers && !expandDrivers.hasOwnProperty(index)
                ? []
                : [driver.id]
              : []
          }
        >
          <Panel
            showArrow={!previewList}
            key={driver.id}
            header={
              <Row
                onClick={(event) => event.stopPropagation()}
                className="driver-list-detail sub-driver"
                gutter={8}
              >
                <div className="mt-10">
                  <span> </span>
                  <Checkbox
                    checked={
                      checkAll
                        ? true
                        : multiSelectDriver.indexOf(driver) !== -1
                        ? true
                        : false
                    }
                    id="check-driver-event-1"
                    onClick={onCheckDriver}
                  />
                  <DriverImage imageUrl={driver?.avatar} />
                  <StatusTooltip driver={driver} />
                </div>
                <div className="driver-list-inn">
                  <DriverTags driver={driver} view={"drivers"} />
                  <div>
                    <p className="carrier-fedex ">
                      Carrier: <small>{driver.carrier_name} </small>
                    </p>{" "}
                    {/* {userType.tenant.includes(userData.company_type) ? ( */}
                      <p className="carrier-fedex">
                        Zone:{" "}
                        <small>
                          {driver.gsi1sk && driver.gsi1sk !== "ZONE#"
                            ? driver.z_name
                            : "None"}
                        </small>
                      </p>
                    {/* ) : null} */}
                  </div>
                </div>
                <div className="driver-list-modals">
                  <div className="driver-list-icon">
                    <img
                      src={info}
                      alt="info-img"
                      className="info-img"
                      id="info-img-visible-2"
                      onClick={(event) => {
                        event.stopPropagation();
                        dispatch(
                          updateState({
                            drawerDriver: driver,
                            drawerOpen: true,
                            drawerPlacement: "right",
                          })
                        );
                      }}
                    />

                    <>
                      <Dropdown
                        key={"driverlist-dropdown" + driver.id}
                        overlay={
                          <DriverMenu driver={driver} unassignOption={true} />
                        }
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <img
                          src={dots}
                          alt="dots-img"
                          className="dots-img"
                          id="dots-img-visible-3"
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          style={{
                            width: "24px",
                            transform: "rotate(90deg)",
                          }}
                        ></img>
                      </Dropdown>
                    </>
                  </div>
                </div>
              </Row>
            }
          >
            <>
              <Divider />
              <DriverExpandInfo driver={driver} view="Drivers" />
            </>
          </Panel>
        </Collapse>
      );
    }
    return null;
  }, [
    driver,
    checkAll,
    expand,
    collapseAllDrivers,
    checkIt,
    expandDrivers,
    setIndexCollapse,
    rerenderList,
  ]);

  return (
    <div style={style}>
      {previewList ? (
        isOverTarget || multiSelectDriver.length > 1 ? (
          <div className="rectangle-copy-drop">
            <List.Item style={{ padding: "0px" }}>
              <List.Item.Meta
                key={`driver_preview_item_${driver.id}`}
                title={
                  <div className="driver-list">
                    {multiSelectDriver.length > 1 ? (
                      <div className="selected-avatar-group">
                        <Avatar.Group maxCount={2} className="avatar-group">
                          {multiSelectDriver.map((driver) => (
                            <DriverImage
                              imageUrl={driver?.avatar}
                              key={`image_${driver.id}`}
                            />
                          ))}
                        </Avatar.Group>
                        <span className="multi-drag-count">{`${multiSelectDriver.length} Drivers`}</span>
                      </div>
                    ) : (
                      <>
                        <DriverImage imageUrl={driver.avatar} />
                        <span className="drag-count">{`${driver.driver_name} `}</span>
                      </>
                    )}
                  </div>
                }
              />
            </List.Item>
          </div>
        ) : (
          <Row>
            <Col xs={2} sm={4} md={6} lg={9} xl={9}>
              <List.Item
                ref={attachRef}
                role={"driverList"}
                className={`rectangle-copy-1 driver-list-copy ${
                  driver.flag_type !== null ? "flagged-item" : ""
                }`}
                style={dragStyle}
                data-testid={`driver_${index}`}
                draggable="true"
              >
                <List.Item.Meta
                  key={`driver_preview_item_${driver.id}`}
                  title={driverListContent}
                />
              </List.Item>
            </Col>
          </Row>
        )
      ) : (
        <>
          <List.Item
            ref={attachRef}
            role={"driverList"}
            className={"card-list card-list-item"}
            style={
              driver.flag_type !== null
                ? { ...dragStyle, border: "1px solid #FF0505" }
                : dragStyle
            }
            data-testid={`driver_${index}`}
            draggable="true"
          >
            <List.Item.Meta
              key={`driver_tab_${driver.id}`}
              title={driverListContent}
            />
          </List.Item>
        </>
      )}
    </div>
  );
};
export default memo(DriversList);
