import { Tooltip, Row } from "antd";
import { FlagFilled } from '@ant-design/icons'
import { lastUpdatedTime, parseDateCST } from "../../utils/date";
import NoShow from "../../images/noshow-icon.png";
import flagIcon from "../../images/ic_flag.svg";
import bannedIcon from "../../images/Frame.svg";
import { projectLiterals as literals } from "../../utils/projectLiterals";
var moment = require("moment");

const DriverTags = ({ driver, view }) => {
  return (
    <div className="driver-tag-box">
      <Row>
      <h3 className="driver-name">
      <b>{driver.driver_name}</b>
      </h3>
      </Row>
      <Row className="driver-name">
      {driver.flag_type === 'parked'?
      (<img src={flagIcon}  className="flag-icon" alt="parked" />)
      :driver.flag_type === 'banned'?
      (<img src={bannedIcon} className="banned-badge" alt="parked" />)
      :null}
      
      <small
        className={
          driver.shift === "AM" ? "am" : driver.shift === "PM" ? "pm" : "na"
        }
      >
        {driver.shift === "NA" ? "NO" : driver.shift}
      </small>
      {view === 'drivers'?
      <>
        {driver.driver_imported_at &&
            parseDateCST(driver.driver_imported_at).isAfter(
            moment(Date.now()).subtract(4, "d")
            ) &&
            driver.import_tag === "NEW" && (
            <small className="new">{driver.import_tag}</small>
            )}
        {driver.driver_imported_at &&
            parseDateCST(driver.driver_imported_at).isAfter(
            moment(Date.now()).subtract(3, "d")
            ) &&
            driver.import_tag === "OLD" && (
            <small className="old">{driver.import_tag}</small>
            )}
        {parseDateCST(lastUpdatedTime(driver)).isBefore(
            moment(Date.now()).subtract(3, "d")
        ) && (
            <Tooltip title={literals.TOOLTIP.NO_SHOW}>
            <small className="noShow-badge">
                {" "}
                <img src={NoShow} className="noShow-icon" alt="No Show" /> No Show{" "}
            </small>
            </Tooltip>
        )}
      </>
      :null}
      </Row>
   </div>
  );
};
export default DriverTags;
