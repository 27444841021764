/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateByCategory = /* GraphQL */ `
  subscription OnUpdateByCategory($cat: String) {
    onUpdateByCategory(cat: $cat) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteByCategory = /* GraphQL */ `
  subscription OnDeleteByCategory($cat: String) {
    onDeleteByCategory(cat: $cat) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateByGsortKey = /* GraphQL */ `
  subscription OnUpdateByGsortKey($gsi1sk: String) {
    onUpdateByGsortKey(gsi1sk: $gsi1sk) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateByTenantClassKey = /* GraphQL */ `
  subscription OnUpdateByTenantClassKey(
    $classification: String
    $tenant_id: String
  ) {
    onUpdateByTenantClassKey(
      classification: $classification
      tenant_id: $tenant_id
    ) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateByCarrierAndClass = /* GraphQL */ `
  subscription OnUpdateByCarrierAndClass(
    $carrier_id: String
    $classification: String
  ) {
    onUpdateByCarrierAndClass(
      carrier_id: $carrier_id
      classification: $classification
    ) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateById = /* GraphQL */ `
  subscription OnUpdateById($id: ID, $cat: String) {
    onUpdateById(id: $id, cat: $cat) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateByDDTenant = /* GraphQL */ `
  subscription OnUpdateByDDTenant(
    $dd_tenant: String
    $cat: String
    $tenant_id: String
  ) {
    onUpdateByDDTenant(
      dd_tenant: $dd_tenant
      cat: $cat
      tenant_id: $tenant_id
    ) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDriverActivity = /* GraphQL */ `
  subscription OnUpdateDriverActivity($driver_id: String, $type: String) {
    onUpdateDriverActivity(driver_id: $driver_id, type: $type) {
      id
      skey
      type
      actionType
      platform
      user_name
      user_id
      reason
      driver_tenant
      driver_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCatHerd = /* GraphQL */ `
  subscription OnCreateCatHerd($filter: ModelSubscriptionCatHerdFilterInput) {
    onCreateCatHerd(filter: $filter) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCatHerd = /* GraphQL */ `
  subscription OnUpdateCatHerd($filter: ModelSubscriptionCatHerdFilterInput) {
    onUpdateCatHerd(filter: $filter) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCatHerd = /* GraphQL */ `
  subscription OnDeleteCatHerd($filter: ModelSubscriptionCatHerdFilterInput) {
    onDeleteCatHerd(filter: $filter) {
      id
      cat
      type
      gsi1sk
      tenant_id
      dd_tenant
      tenant_name
      tenant_type
      z_name
      status
      terminal
      district
      carrier
      basin
      crew
      jobs
      loads
      drivers
      is_assigned
      assign_load_status
      assigned_load_ids
      am_shift_start_time
      am_shift_buffer_time
      pm_shift_start_time
      pm_shift_buffer_time
      cc_email
      cc_supervisor_email
      am_alert_time
      pm_alert_time
      am_supervisor_time
      pm_supervisor_time
      z_notes
      note_read_by
      load_name
      load_no
      load_id
      job_id
      job_type
      is_appt_time_required
      is_stage_required
      linked_terminal_id
      terminal_id
      terminal_name
      linked_product_id
      product_id
      product_name
      stage
      assigned_driver
      load_status
      load_created_at
      carrier_status
      operator_id
      operator_name
      product_inventory {
        product_name
        on_site_inventory
        __typename
      }
      box_inventory {
        product_name
        loaded_boxes
        partial_empty_boxes
        empty_boxes
        __typename
      }
      job_name
      load_ids
      district_id
      district_name
      crew_id
      crew_name
      basin_id
      basin_name
      linked_carrier {
        carrier_id
        carrier_name
        carrier_linked_id
        carrier_status
        carrier_linked_status
        __typename
      }
      job_status
      working_status
      progress_status
      active_trucks
      avg_total_time
      avg_travel_time
      avg_transition_time
      driver_name
      driver_id
      classification
      phone_no
      line_up_order
      email
      on_duty
      driver_carrier_tenant
      shift
      carrier_id
      carrier_name
      accepted_load_id
      assigned_boxes_new
      last_delivered_date
      last_load_job
      last_load_delivered
      check_in
      checked_time
      accepted_on_during_checked_in
      trailer_number
      truck_number
      user_id
      turns_per_shift
      no_of_load_delivered_in_current_shift
      driver_status
      driver_linked_status
      avatar
      no_of_assigned_loads
      active_load
      driver_imported_at
      import_tag
      shift_changed_time
      driver_flagged
      flag_type
      agg_drivers
      agg_active_drivers
      agg_active_loads
      am_target
      pm_target
      shift_alert_email {
        id
        name
        email
        __typename
      }
      shift_alert_sms {
        id
        name
        phone_no
        __typename
      }
      custom_contact {
        id
        reference
        type
        value
        __typename
      }
      triggerType
      avg_pump_time
      last_stage_pumped {
        stage_no
        well
        pump_time
        avg_box_time
        transition_time
        total_boxes
        total_pumped
        __typename
      }
      product_details {
        pumped
        boxes_cashed
        product_name
        __typename
      }
      last_update_trucking
      last_update_well_site
      last_update_stage_pumped
      last_update_inventory
      total_drivers
      success_drivers
      fail_drivers
      orignal_file
      exception_file
      uploaded_at
      uploaded_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateGlobalLookups = /* GraphQL */ `
  subscription OnCreateGlobalLookups(
    $filter: ModelSubscriptionGlobalLookupsFilterInput
  ) {
    onCreateGlobalLookups(filter: $filter) {
      id
      cat
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateGlobalLookups = /* GraphQL */ `
  subscription OnUpdateGlobalLookups(
    $filter: ModelSubscriptionGlobalLookupsFilterInput
  ) {
    onUpdateGlobalLookups(filter: $filter) {
      id
      cat
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteGlobalLookups = /* GraphQL */ `
  subscription OnDeleteGlobalLookups(
    $filter: ModelSubscriptionGlobalLookupsFilterInput
  ) {
    onDeleteGlobalLookups(filter: $filter) {
      id
      cat
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateReports = /* GraphQL */ `
  subscription OnCreateReports($filter: ModelSubscriptionReportsFilterInput) {
    onCreateReports(filter: $filter) {
      id
      type
      zone_name
      driver_id
      driver_name
      description
      report_status
      carrier_id
      carrier_name
      tenant_id
      DD_current_load
      DD_current_load_status
      PD_current_load
      PD_current_load_status
      DD_check_in
      PD_check_in
      DD_driver_available
      PD_driver_available
      DD_ready_for_load_time
      PD_ready_for_load_time
      DD_last_load_no
      PD_last_load_no
      DD_last_job_name
      PD_last_job_name
      PD_last_delivered_date
      DD_last_delivered_date
      zone_shift
      DD_driver_shift
      PD_driver_shift
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateReports = /* GraphQL */ `
  subscription OnUpdateReports($filter: ModelSubscriptionReportsFilterInput) {
    onUpdateReports(filter: $filter) {
      id
      type
      zone_name
      driver_id
      driver_name
      description
      report_status
      carrier_id
      carrier_name
      tenant_id
      DD_current_load
      DD_current_load_status
      PD_current_load
      PD_current_load_status
      DD_check_in
      PD_check_in
      DD_driver_available
      PD_driver_available
      DD_ready_for_load_time
      PD_ready_for_load_time
      DD_last_load_no
      PD_last_load_no
      DD_last_job_name
      PD_last_job_name
      PD_last_delivered_date
      DD_last_delivered_date
      zone_shift
      DD_driver_shift
      PD_driver_shift
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteReports = /* GraphQL */ `
  subscription OnDeleteReports($filter: ModelSubscriptionReportsFilterInput) {
    onDeleteReports(filter: $filter) {
      id
      type
      zone_name
      driver_id
      driver_name
      description
      report_status
      carrier_id
      carrier_name
      tenant_id
      DD_current_load
      DD_current_load_status
      PD_current_load
      PD_current_load_status
      DD_check_in
      PD_check_in
      DD_driver_available
      PD_driver_available
      DD_ready_for_load_time
      PD_ready_for_load_time
      DD_last_load_no
      PD_last_load_no
      DD_last_job_name
      PD_last_job_name
      PD_last_delivered_date
      DD_last_delivered_date
      zone_shift
      DD_driver_shift
      PD_driver_shift
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateActivityLog = /* GraphQL */ `
  subscription OnCreateActivityLog(
    $filter: ModelSubscriptionActivityLogFilterInput
  ) {
    onCreateActivityLog(filter: $filter) {
      id
      skey
      type
      actionType
      platform
      user_name
      user_id
      reason
      driver_tenant
      driver_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateActivityLog = /* GraphQL */ `
  subscription OnUpdateActivityLog(
    $filter: ModelSubscriptionActivityLogFilterInput
  ) {
    onUpdateActivityLog(filter: $filter) {
      id
      skey
      type
      actionType
      platform
      user_name
      user_id
      reason
      driver_tenant
      driver_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteActivityLog = /* GraphQL */ `
  subscription OnDeleteActivityLog(
    $filter: ModelSubscriptionActivityLogFilterInput
  ) {
    onDeleteActivityLog(filter: $filter) {
      id
      skey
      type
      actionType
      platform
      user_name
      user_id
      reason
      driver_tenant
      driver_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
