import { useDrag, useDrop } from "react-dnd";
import {
  List,
  Col,
  Row,
  Collapse,
  Divider,
  Dropdown,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import info from "../../images/info.svg";
import dots from "../../images/three-dots.png";
import { getEmptyImage } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as BoxIcon } from "../../images/box-icon.svg";
import DriverMenu from "../../Components/Menus/DriverMenu";
import DriverImage from "../../Components/DriverComponents/DriverAvatar";
import StatusTooltip from "../../Components/DriverComponents/DriverStatusTooltip";
import DriverTags from "../../Components/DriverComponents/DriverTags";
import DriverExpandInfo from "../../Components/DriverComponents/DriverExpandInfo";
import { updateState } from "../../store/actions/main_actions";

const { Panel } = Collapse;

const UserList = ({
  index,
  user,
  ItemTypes,
  toggleDriversDetail,
  isOverTarget,
  previewList,
}) => {
  const showUnassigned = useSelector(
    (state) => state.mainReducer.showUnassigned
  );
  const {boardTab} = useSelector((state) => state.mainReducer.driverFilters);
  const [isOpen, setIsOpen] = useState(toggleDriversDetail);
  useEffect(() => {
    if (toggleDriversDetail) setIsOpen(true);
    else setIsOpen(false);
  }, [toggleDriversDetail]);

  function attachRef(el) {
    drag(el);
    drop(el);
  }
  useEffect(() => {}, [user]);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "load",
      drop: () => ({ ...user, index: index }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [user]
  );
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "user",
      item: { ...user, index: index },
      end: (seletedItem, monitor) => {
        // const dropResult = monitor.getDropResult();
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [user]
  );
  preview(getEmptyImage(), { captureDraggingState: true });

  let opacity = isDragging ? 0.3 : 1;
  // let transform = isDragging ? "rotate(7deg)" : "";
  let border1 = "";
  let border = isDragging ? "2px dashed #d2d4db" : "";
  const isActive = canDrop && isOver;
  let backgroundColor = "white";
  let borderRadius = "";
  let marginLeft = "";
  if (isActive) {
    backgroundColor = "rgb(230, 235, 238)";
    border1 = "1px solid #093254";
    borderRadius = "4px";
    marginLeft = "8px";
  } else if (canDrop) {
    border1 = "1px solid #093254";
    borderRadius = "4px";
    marginLeft = "8px";
  }
  let dragStyle ={
    cursor: "move",
    opacity,
    backgroundColor,
    border,
    border1,
    borderRadius,
    marginLeft,
  }
  const DriverAvatar = ({ user, userIndex }) => {
  const dispatch = useDispatch();
  return(
    <Collapse
      collapsible="icon"
      accordion
      bordered={false}
      ghost
      expandIconPosition="end"
      activeKey={isOpen ? [userIndex] : []}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Panel
        showArrow={!previewList}
        key={userIndex}
        header={
          <Row
            className="driver-list-detail"
            gutter={8}
            onClick={(event) => event.stopPropagation()}
          >
            <div className="avatar-box">
              <div className="avatar-circle">
              {showUnassigned ? null : boardTab.view_type !== "Queue" ? (
                <></>
              ) : (
                <span className="driver-no"> {user?.line_up_order ?? 0} </span>
              )}
              <DriverImage imageUrl={user?.avatar} />
              <StatusTooltip driver={user}/>
              </div>
            </div>
            <div className="group-2" key={uuidv4()}>
              <DriverTags driver={user} view="board_tab"/>
              <div key={uuidv4()}>
                <p className="carrier-fedex  w-330">
                  Carrier: <small>{user.carrier_name} </small>
                </p>

                {user.is_assigned === true ? (
                  user.assign_load_status === "1" ? null : (
                    // <p className="carrier-fedex  w-330">
                    //   Assigned Loads: <small>{user.assigned_load_ids.toString()} </small>
                    // </p>
                    <>
                      <p className="carrier-fedex  w-330">
                        Job: <small>{user.job_name} </small>
                      </p>
                      <p className="carrier-fedex  w-330">
                        Load No: <small>{user.load_no} </small>
                      </p>
                    </>
                  )
                ) : null}
                {/* <p className="carrier-fedex w-330">
                  Check-in:{" "}
                  <small>
                    {user.check_in === "1" &&
                    !isNaN(parseDate(user.checked_time))
                      ? moment(parseDate(user.checked_time)).format(
                          "MM/DD/YYYY hh:mm A"
                        )
                      : "n/a"}
                  </small>
                </p> */}
              </div>
              {!user.is_assigned && (
                <div className="box-container" key={uuidv4()}>
                  <span className="boxes-field"> Boxes: </span>
                  {user.assigned_boxes_new
                    ? JSON.parse(user?.assigned_boxes_new).map((box, index) =>
                        box.id !== "None" && box.box_number !== 0 ? (
                          <div
                            className="box-tag"
                            style={{
                              backgroundColor:
                              box.proppant_type === "Hybrid Wet" || box.proppant_type === "Hybrid Dry"
                              ? "#7EF64C":
                                box.proppant_type === "Wet"
                                  ? "#EBF0FE"
                                  : "#ECEFF4",
                            }}
                          >
                            <BoxIcon
                              className="box-icon"
                              style={{
                                color:
                                  box.proppant_type === "Wet"
                                    ? "#376AF7"
                                    : "#58627A",
                              }}
                            />
                            <span className="box-tag-text">
                              {box.box_number}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="box-tag"
                            style={{ backgroundColor: "#F4ECEC" }}
                          >
                            <BoxIcon
                              className="box-icon"
                              style={{ color: "#F73B3B" }}
                            />
                            <span
                              className="box-tag-text"
                              style={{ color: "#f73b3b" }}
                            >
                              None
                            </span>
                          </div>
                        )
                      )
                    : null}
                </div>
              )}
            </div>
            <div className="driver-list-modals">
              <div className="driver-list-icon">
                {/* <img
                  src={calendarImg}
                  alt="calendar-img"
                  disabled={true}
              className="info-img"
                  onClick={(e) => {
                    setPlacement("bottom");
                    setVisible(false);
                  }}
                /> */}
           
                <img
                  src={info}
                  alt="info-img"
                  className="info-img"
                  id="driver-info-icon-visible"
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(updateState({ drawerDriver: user, drawerOpen: true, drawerPlacement: "right"}));
                  }}
                />
                <>
                  <Dropdown
                    overlay={
                      <DriverMenu driver={user} unassignOption={false} />
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <img
                      src={dots}
                      alt="dots-img"
                      className="dots-img"
                      id="driver-info-icon-visible-3"
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                      style={{
                        width: "24px",
                        transform: "rotate(90deg)",
                      }}
                    ></img>
                  </Dropdown>
                </>
              </div>
            </div>
          </Row>
        }
      >
        <>
          <Divider />
          <DriverExpandInfo driver={user} view="Board" showUnassigned={showUnassigned}/>
        </>
      </Panel>
    </Collapse>
  )};
  const listItem = (className) => (
    <div
      id={`my_user_${index}`}
      ref={attachRef}
      role={"UserList"}
      className={className}
      style={user.flag_type !== null && className === 'card-list'? 
        {...dragStyle, border:'1px solid #FF0505'} :
        dragStyle}
      data-testid={`user_${index}`}
    >
      <List.Item key={uuidv4()}>
        <List.Item.Meta
          key={`driver_preview_item_${user.id}`}
          title={<DriverAvatar user={user} userIndex={index} key={uuidv4()} />}
        />
      </List.Item>
    </div>
  );

  return previewList ? (
    isOverTarget ? (
      <div className="rectangle-copy-drop" key={uuidv4()}>
        <List.Item style={{ padding: "0px" }} key={uuidv4()}>
          <List.Item.Meta
            title={
              <div>
                <DriverImage imageUrl={user?.avatar} />
                <span className="drag-count">{user.driver_name}</span>
              </div>
            }
          />
        </List.Item>
      </div>
    ) : (
      <Row>
        <Col xs={2} sm={4} md={6} lg={9} xl={9}>
          {listItem("rectangle-copy-1")}
        </Col>
      </Row>
    )
  ) : (
    <div>
      {listItem("card-list")}
    </div>
  );
};
export default React.memo(UserList);
