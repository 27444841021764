import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Divider, Spin } from "antd";
import { GetProgressBar } from "../../Components/Zone";
import { getAWSApiLinks } from "../../services/apiCalls";
import axios from "axios";
import { MessageOutlined, PhoneOutlined } from "@ant-design/icons";
import noDriver from "../../images/no-driver.svg";
import noData from "../../images/empty-state.svg";
import { generateClient } from 'aws-amplify/api';
import {
  onUpdateById,
  onUpdateByTenantClassKey,
} from "../../graphql/subscriptions";
import ZoneTargetPanel from "./ZoneTargetInfo";
import { projectLiterals as literals } from "../../utils/projectLiterals";

const API = generateClient();

var moment = require("moment");
// import './happeningNow.css'

const getParams = (search) => {
  search = search.replaceAll("?", "");
  search = search.split("/");
  let params = {};
  search.forEach((param) => {
    let values = param.split("=");
    if(values[1] !== null && values[1] !== 'null'){
      params[values[0]] = values[1];
    }
  });
  return params;
};
const HappeningNow = () => {
  const [data, setData] = useState({});
  const location = useLocation();
  const [dateTime, setDateTime] = useState("");
  const [currentShift, setCurrentShift] = useState("AM");
  const [loading, setLoading] = useState(true);
  const [urlParams, setUrlParams] = useState({});
  const getData = () => {
    var time = new Date();
    setDateTime(time.toLocaleString("en-US"));
    let shift = time
      .toLocaleString("en-US", { hour: "numeric", hour12: true })
      .includes("AM")
      ? "AM"
      : "PM";
    setCurrentShift(shift);
    let baseUrl = getAWSApiLinks("dynamoElasticSyncApi");
    if (baseUrl !== null) {
      const options = {
        url: `${baseUrl}/search`,
        method: "POST",
        data: urlParams.carrier
          ? {
              action_type: "OPEN_URL_CARRIER_INFO",
              zone_id: urlParams.zone,
              carrier_id: urlParams.carrier,
            }
          : {
            zone_id: urlParams.zone,
            action_type: "OPEN_URL_ZONE_CARRIER_INFO"
          },
      };
      axios(options)
        .then((response) => {
          setData(response?.data ?? {});
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    } else {
      setData({});
      setLoading(false);
    }
  };

  function pad(num) {
    return ("0" + parseInt(num)).substr(-2);
  }

  function tick() {
    if (data?.AM?.shift_start && data?.PM?.shift_start) {
      let start = new Date();
      let shift_start =
        currentShift === "AM"
          ? data.PM.shift_start.split(":")
          : data.AM.shift_start.split(":");
      start.setHours(shift_start[0] - 5, shift_start[1], shift_start[2]);
      var now = new Date();
      if (now > start) {
        // too late, go to tomorrow
        start.setDate(start.getDate() + 1);
      }
      var remain = (start - now) / 1000;
      var hh = pad((remain / 60 / 60) % 60);
      var mm = pad((remain / 60) % 60);
      var ss = pad(remain % 60);
      if (hh === pad(0) && mm === pad(0) && ss === pad(0)) {
        getData();
      }
      // console.log(hh + ":" + mm + ":" + ss )
      setTimeout(tick, 1000);
    }
  }

  useEffect(() => {
    tick();
  }, [data]);
  useEffect(() => {
    let params = getParams(location.search);
    setUrlParams(params);
    return() => {
      setUrlParams({});
    }
  }, []);
  useEffect(() => {
    if (Object.keys(urlParams).length > 0) {
      const zoneSubscription = API.graphql({
        query: onUpdateById,
        variables: {
          id: `ZONE#${urlParams.zone}`,
          cat: "ZONE",
        },
        authMode: literals.API.AUTHMODE,
        authToken: "EMAIL_ACCESS_DD",
      }).subscribe({
        next: ({ provider, value }) => {
          getData();
        },
        error: (error) => console.warn(error),
      });
      const driverSubscription = API.graphql({
        query: onUpdateByTenantClassKey,
        variables: {
          classification: "DRIVER",
          tenant_id: urlParams.tanent,
        },
        authMode: literals.API.AUTHMODE,
        authToken: "EMAIL_ACCESS_DD",
      }).subscribe({
        next: ({ provider, value }) => {
          getData();
        },
        error: (error) => console.warn(error),
      });
      const carrierSubscription = API.graphql({
        query: onUpdateByTenantClassKey,
        variables: {
          classification: "TARGET",
          tenant_id: urlParams.tanent,
        },
        authMode: literals.API.AUTHMODE,
        authToken: "EMAIL_ACCESS_DD",
      }).subscribe({
        next: ({ provider, value }) => {
          getData();
        },
        error: (error) => console.warn(error),
      });
      getData();
      return () => {
        driverSubscription.unsubscribe();
        zoneSubscription.unsubscribe();
        carrierSubscription.unsubscribe();
      };
    }
  }, [urlParams]);
  const ShiftStats = ({ shiftData, shift, shiftName }) => (
    <>
      <p>
        {shiftName} Shift: {shift} Shift
        <br />
        {shift} Shift Start:{" "}
        {moment(moment.utc(shiftData.shift_start, "HH:mm:ss"))
          .subtract(5, "hours")
          .format("HH:mm")}{" "}
        CST
        <br />
        <br />
        <small className={shift.toLowerCase()}>{shift}</small>
        Checked in{" "}
        <span style={{ color: "green", fontWeight: 500 }}>
          {shiftData?.stats?.checkedIn ?? 0}
        </span>{" "}
        • Actual{" "}
        <span style={{ color: "orange", fontWeight: 500 }}>
          {shiftData?.stats?.actual ?? 0}
        </span>{" "}
        • Target{" "}
        <span style={{ color: "red", fontWeight: 500 }}>
          {shiftData?.stats?.targets ?? 0}
        </span>
      </p>
      <GetProgressBar
        barNum={[
          shiftData?.stats?.checkedIn ?? 0,
          shiftData?.stats?.actual ?? 0,
          shiftData?.stats?.targets ?? 0,
        ]}
      />
    </>
  );
  const Drivers = ({ shiftData }) => (
    <>
      <Card className="checked-in-card" size="small" title="CHECKED IN">
        {shiftData?.drivers?.checkedIn.length > 0 ? (
          shiftData?.drivers?.checkedIn.map((driver) => (
            <div className="driver-status-list d-flex justify-content-space">

              <div>
           <span className="fs-16 fw-500"> {driver._source.driver_name}
           </span>  
              </div>

              <div className="">
              <a href={`tel:${driver._source.phone_no}`}>
                <PhoneOutlined />
              </a>
              <a href={`sms:${driver._source.phone_no}`}>
                <MessageOutlined />
              </a>

              </div>
            </div>
          ))
        ) : (
          <div>
                <div className="no-drivers-dataa">
            <img className="sort-icon " src={noDriver} alt="No Driver" />
            <b> No Drivers</b>
          
              </div>
          
              <p className="text-center">There are currently no drivers for this shift.</p>
          </div>
        )}
      </Card>
      <Card size="small" className="not-checked-in-card" title="NOT CHECKED IN">
        {shiftData?.drivers?.notCheckedIn.length > 0 ? (
          shiftData?.drivers?.notCheckedIn.map((driver) => (
            <div className=" driver-status-list d-flex justify-content-space">

            <div>
         <span className="fs-16 fw-500"> {driver._source.driver_name}
         </span>  
            </div>

            <div className="">
            <a href={`tel:${driver._source.phone_no}`}>
              <PhoneOutlined />
            </a>
            <a href={`sms:${driver._source.phone_no}`}>
              <MessageOutlined />
            </a>

            </div>
          </div>



          ))
        ) : (
          <div>

            <div className="no-drivers-dataa">
            <img className="sort-icon " src={noDriver} alt="No Driver" />
            <b> No Drivers</b>
                
         
              </div>
              <p className="text-center">There are currently no drivers for this shift.</p>
          </div>
        )}
      </Card>
    </>
  );
  return (
    <div className="happening-now-wrapper">
      <Spin spinning={loading} className="spinner-wrapper">
        {urlParams.carrier ? (
          data.carrierName ? (
            <div>
              <Card
                className="card-contents"
                title={data?.carrierName}
                bordered={false}
              >
                <p className="mb-18 mt-13 ">Last Update: {dateTime}</p>
                <div>
                  <h2>Happening Now</h2>
                  <ShiftStats
                    shiftData={currentShift === "AM" ? data.AM : data.PM}
                    shift={currentShift === "AM" ? "AM" : "PM"}
                    shiftName={"Current"}
                  />
                  <Drivers
                    shiftData={currentShift === "AM" ? data.AM : data.PM}
                  />
                </div>

                <div>
                  <Divider />
                  <h2>Coming Up</h2>
                  <ShiftStats
                    shiftData={currentShift !== "AM" ? data.AM : data.PM}
                    shift={currentShift !== "AM" ? "AM" : "PM"}
                    shiftName={"Next"}
                  />
                  <Drivers
                    shiftData={currentShift !== "AM" ? data.AM : data.PM}
                  />
                </div>
              </Card>
            </div>
          ) : loading ? null : (
            <div className="no-data-wrapper">
              <img src={noData} alt="zone" /> <p>No Data Found</p>
            </div>
          )
        ) : (
          data.name?
          <Card
            className="card-contents"
            title={data?.name}
            bordered={false}
          >
            <ZoneTargetPanel zoneInfo={data}/>
          </Card>
          : loading ? null : (
            <div className="no-data-wrapper">
              <img src={noData} alt="zone" /> <p>No Data Found</p>
            </div>
          )
        )}
      </Spin>
    </div>
  );
};
export default HappeningNow;
