// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { CatHerd, GlobalLookups, Reports, ActivityLog, LastStagePumped, ProductDetails, ProductInventory, BoxInventory, LinkedCarrier, UserEmail, UserSMS, CustomContact } = initSchema(schema);

export {
  CatHerd,
  GlobalLookups,
  Reports,
  ActivityLog,
  LastStagePumped,
  ProductDetails,
  ProductInventory,
  BoxInventory,
  LinkedCarrier,
  UserEmail,
  UserSMS,
  CustomContact
};