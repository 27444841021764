import { useDrag, useDrop } from "react-dnd";
import { List, Collapse, Row, Col, Divider } from "antd";
import { getEmptyImage } from "react-dnd-html5-backend";

const style = {
  cursor: "move",
};
const { Panel } = Collapse;

export const Jobs = ({ index, job, ItemTypes, toggleLoadsdDetail, previewList }) => {
  const [{ isDragging }, preview] = useDrag(() => ({
    type: 'job',
    item: job,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // let userData = userdata;
        // userData[dropResult.index].job.push(jobData[index]);
        // let _jobData = jobData;
        // _jobData.splice(index, 1);
        // dispatch(
        //   updateState({ userdata: [...userData], jobData: [..._jobData] })
        // );
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));
  preview(getEmptyImage(), { captureDraggingState: true }) 

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'driver',
    drop: () => ({ ...job}),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const opacity = isDragging ? 0 : 1;
  // const getHeader = ({ job }) => <h4>{job.name}</h4>;
  const LoadAvatar = ({ job, jobIndex }) => (
    <Collapse
      accordion
      bordered={false}
      ghost
      className="load-panel"
      expandIconPosition="end"
      defaultActiveKey={toggleLoadsdDetail ? [jobIndex] : []}
    >
      <Panel
        key={jobIndex}
        header={
          <div style={{ "textAlign": "left" }}>
            <h4>
              <b>{job.job_name || "UnKnown"}</b>
            </h4>
            <span>Job No: {job.job_name || "UnKnown"}</span>
          </div>
        }
      >
        <>
          <Divider />
          {/* <Row>
            <Col span={12}>
              Terminal
              <br />
              Hi Crush Kermit
            </Col>
            <Col span={12}>
              Product
              <br />
              100 Mesh
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              Stage
              <br />
              03274974
            </Col>
            <Col span={12}>
              Est. Hauling
              <br />
              $382,388,893,00
            </Col>
          </Row> */}
        </>
      </Panel>
    </Collapse>
  );
  // function jobRef(el) {
  //   drag(el);
  //   drop(el);
  // }
  const isActive = canDrop && isOver;
  let backgroundColor = "white";
  let border = "";
  let borderRadius = "";
  let  marginLeft="";
  if (isActive) {
    backgroundColor = "rgb(230, 235, 238)";
    border = "1px solid #093254";
    borderRadius="4px";
    marginLeft="8px"
  } else if (canDrop) {
    border = "1px solid ##093254";
    borderRadius="4px";
    marginLeft="8px"
  }
  const listItem = (itemClassName) =>(
    <div
      ref={drop}
      role="job"
      style={{ ...style, opacity, backgroundColor, border,borderRadius , marginLeft}}
      data-testid={index}
      className={itemClassName}
    >
      <List.Item style={{ padding: "0px" }}>
        <List.Item.Meta title={<LoadAvatar job={job} jobIndex={index} />} />
      </List.Item>
    </div>
  )

  return (
    (previewList)?
    <Row>
      <Col xs={2} sm={4} md={6} lg={9} xl={9}>
          {listItem("rectangle-copy-1")}
      </Col>
    </Row>
    :
    <>
      {listItem('card-list')}
    </>
  );
};
