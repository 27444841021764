const ZoneTargetStats = ({withColor, stats}) =>{
    return(
        <span>
            Check in{" "}
            <span style={withColor?{ color: "green", fontWeight: "bold" }:null}>
                {" "}{stats.checkIn}{" "}
            </span>
            • Actual{" "}
            <span style={withColor?{ color: "orange", fontWeight: "bold" }:null}>
                {" "}{stats.actual}{" "}
            </span>
            • Target{" "}
            <span style={withColor?{ color: "red", fontWeight: "bold" }:null}>
                {stats.target}
            </span>
        </span>
    )
}
export default ZoneTargetStats