import { Form, Input, Button, Checkbox, Row, Col, notification } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { If, Then, Else } from "react-if";
import { loginUser } from "../../store/actions/login_actions";
import emailIcon from "../../images/Email.svg";
import { Spin } from "antd";
import { apiRequestAxio } from "../../services/axioCall";
import Logo from "../../images/login-logo.png";

const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const previousPath = location?.state?.from ?? null;
  const navigate = useNavigate();
  const [forgetPass, setForgetPass] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [toggleMailScreen, setToggleMailScreen] = useState(false);
  const [seconds, setSeconds] = useState(10);
  const [timerInterval, setTimerInterval] = useState(null);
  const [inputEmail] = useState("");
  const [loginInprogress, setLoginInprogress] = useState(false);
  const query = useQuery();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("userToken");

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(timerInterval);
      setTimerInterval(null);
      setSeconds(10);
    }
  }, [seconds]);
  useEffect(() => {
    if (query.get("mode")) {
      setResetPassword(true);
    }
    return () => {
      clearInterval(timerInterval);
      setTimerInterval(null);
      setSeconds(10);
      setResetPassword(false);
    };
  }, []);
  useEffect(() => {
    if (token) {
      validateToken(token);
    }
  }, [token]);
  const validateToken = async (token) => {
    try {
      setLoginInprogress(true);
      let { data } = await apiRequestAxio(
        "GET",
        "user/tokenValidator",
        {},
        {
          token: token,
          type: "2",
          "X-API-KEY": process.env.REACT_APP_X_API_KEY,
        }
      );
      if (data.status && data.code === 200) {
        dispatch(
          loginUser({
            isUserLogin: true,
            userData: data.data,
          })
        );
        setLoginInprogress(false);
        navigate("/");
      }
    } catch (e) {
      setLoginInprogress(false);
      navigate("/login");
      notification.open({
        description: "User Authentication Failed",
        placement: "bottomLeft",
      });
      console.log(e);
    }
  };
  const onFinish = (values, trigger) => {
    switch (trigger) {
      case "login":
        authenticateUser(values);
        break;
      case "forgot":
        forgotPassword(values);
        break;
      case "resetPass":
        resetUserPassword(values);
        break;
      default:
        break;
    }
  };
  const resetUserPassword = (values) => {
    setForgetPass(false);
    setResetPassword(false);
  };
  const authenticateUser = async (formObj) => {
    try {
      setLoginInprogress(true);
      let { data } = await apiRequestAxio("POST", "user/login", {
        email: formObj.email.trim(),
        password: formObj.password,
      });
      setLoginInprogress(false);
      dispatch(
        loginUser({
          isUserLogin: true,
          // cognitoUser: user.storage,
          userData: data.data,
        })
      );
      if (previousPath !== null) {
        if (previousPath.pathname.includes("happeningNow")) {
          navigate(`${previousPath.pathname}${previousPath.search}`);
        } else navigate("/");
      } else navigate("/");
    } catch (error) {
      setLoginInprogress(false);
      if (error.response) {
        let data = error.response.data;
        if (data.code === "400" || data.code === 400) {
          notification.open({
            description: "Wrong Email or Password.",
            placement: "bottomLeft",
          });
        } else if (data.code === "403" || data.code === 403) {
          notification.open({
            description: data.message,
            placement: "bottomLeft",
          });
        } else {
          notification.open({
            description: data.message,
            placement: "bottomLeft",
          });
        }
        console.log("error signing in", error);
      }
    }
  };
  const forgotPassword = (values) => {
    setForgetPass(false);
    setResetPassword(true);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="login-page">
      <div className="login-page-body">
        <div className="login-logo">
          <span className="logo">
            {" "}
            <img src={Logo} alt="logo" />{" "}
          </span>
        </div>

        <div className="card-centered">
          <div className="card log-card">
            {token ? (
              <div>
                <h1>
                  <Spin size="large" spinning={loginInprogress} /> Loging User
                </h1>
              </div>
            ) : (
              <div className="brand">
                {/* <img className="logo" src={companyLogo} alt="company logo" /> */}

                <If condition={forgetPass && !toggleMailScreen}>
                  <Then>
                    <Form
                      name="forgot"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      initialValues={{ remember: true }}
                      onFinish={(values) => onFinish(values, "forgot")}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      {
                        <Form.Item
                          wrapperCol={{ span: 24 }}
                          style={{ marginBottom: "8px" }}
                        >
                          <h1 className="login-title">Forgot Password?</h1>
                        </Form.Item>
                      }
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <p style={{ marginBottom: "12px" }}>
                          {" "}
                          Input your email verification, we will send you a link
                          to reset your password.
                        </p>
                      </Form.Item>
                      <Form.Item
                        initialValue={inputEmail}
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="primary" htmlType="submit">
                          Send
                        </Button>
                      </Form.Item>
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <p>
                          Did you remember your password?
                          <a
                            className="try-login"
                            onClick={() => {
                              setForgetPass(false);
                              setToggleMailScreen(false);
                              setResetPassword(false);
                            }}
                          >
                            {" "}
                            Try logging in
                          </a>
                        </p>
                      </Form.Item>
                    </Form>
                  </Then>
                  <Else>
                    <If condition={resetPassword}>
                      <Then>
                        <Form
                          name="reset"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          initialValues={{ remember: true }}
                          onFinish={(values) => onFinish(values, "resetPass")}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item wrapperCol={{ span: 24 }}>
                            {/* <h1 className="login-title">Reset Password</h1> */}
                            <h1 className="login-title">
                              Create a new password
                            </h1>
                          </Form.Item>
                          <Form.Item
                            label="New Password"
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please input new password!",
                              },
                            ]}
                          >
                            <Input.Password />
                          </Form.Item>
                          <Form.Item
                            label="Re-type Password"
                            name="retype-password"
                            rules={[
                              {
                                required: true,
                                message: "Please retype new password!",
                              },
                            ]}
                          >
                            <Input.Password />
                          </Form.Item>
                          <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type="primary" htmlType="submit">
                              Create
                            </Button>
                          </Form.Item>
                        </Form>
                      </Then>
                      <Else>
                        <If condition={toggleMailScreen}>
                          <Then>
                            <Form
                              name="resent"
                              labelCol={{ span: 24 }}
                              initialValues={{ remember: true }}
                              onFinish={(values) => onFinish(values, "forgot")}
                              onFinishFailed={onFinishFailed}
                              autoComplete="off"
                            >
                              <Form.Item wrapperCol={{ span: 24 }}>
                                <div className="circle">
                                  <img src={emailIcon} alt="notify"></img>
                                </div>

                                <h1 className="login-title">
                                  Check your email
                                </h1>
                              </Form.Item>
                              <Form.Item wrapperCol={{ span: 24 }}>
                                <p>We have sent a link to reset your email</p>
                              </Form.Item>
                              <Form.Item>
                                <Row style={{ backgroundColor: "lightGrey" }}>
                                  <Col span={20}>{inputEmail}</Col>
                                  <Col span={4}>
                                    <a
                                      onClick={() => {
                                        setToggleMailScreen(false);
                                        setResetPassword(true);
                                      }}
                                    >
                                      Change
                                    </a>
                                  </Col>
                                </Row>
                              </Form.Item>
                              <Form.Item wrapperCol={{ span: 24 }}>
                                <p>
                                  {"Did not recived? "}
                                  {timerInterval !== null ? (
                                    <b>Resend in 00:{seconds}</b>
                                  ) : (
                                    <a onClick={forgotPassword}>Resend</a>
                                  )}
                                </p>
                              </Form.Item>
                            </Form>
                          </Then>
                          <Else>
                            <Spin tip="Loading" spinning={loginInprogress}>
                              <Form
                                name="login"
                                labelCol={{ span: 24 }}
                                initialValues={{ remember: true }}
                                onFinish={(values) => onFinish(values, "login")}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                              >
                                <Form.Item wrapperCol={{ span: 24 }}>
                                  <h1 className="login-title">Login</h1>
                                </Form.Item>
                                <Form.Item
                                  label="Email"
                                  name="email"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your email!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Password"
                                  name="password"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your password!",
                                    },
                                  ]}
                                >
                                  <Input.Password />
                                </Form.Item>
                                <Row>
                                  <Col span={12}>
                                    <Form.Item
                                      name="remember"
                                      valuePropName="checked"
                                    >
                                      <Checkbox className="remember">
                                        Remember me
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <a onClick={() => setForgetPass(true)}>
                                      {" "}
                                      Forgot Password?{" "}
                                    </a>
                                  </Col>
                                </Row>

                                <Form.Item>
                                  <Button
                                    id="login-btn"
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loginInprogress ? true : false}
                                  >
                                    {loginInprogress ? <Spin /> : null}
                                    Login
                                  </Button>
                                </Form.Item>
                              </Form>
                            </Spin>
                          </Else>
                        </If>
                      </Else>
                    </If>
                  </Else>
                </If>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
