import "antd/dist/antd.css";
import "./App.css";
import { persistor } from "./store/store";
import { CustomDragLayer } from "./Components/DragLayer/CustomDragLayerComp";
import { PersistGate } from "redux-persist/integration/react";
import { Amplify} from 'aws-amplify';
import { DataStore } from 'aws-amplify/datastore';
import amplifyconfig from './amplifyconfiguration.json';
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "./store/actions/login_actions";
import TagManager from 'react-gtm-module';
import RouterConfig from "./routes/RouterConfig";
import { projectLiterals as literals} from "./utils/projectLiterals";

if(process.env.REACT_APP_ENV === literals.ENVIROMENTS.PROD && process.env.REACT_APP_ENV){
  TagManager.initialize({
    gtmId: literals.TAGMANAGER.GTMID,
    dataLayer: {
      platform: literals.TAGMANAGER.PLATFORM
    }
  })
}
try {
  Amplify.configure(amplifyconfig);
} catch (err) {
  console.log("Appologies Unable to configure Amplify");
}
function App() {
  const dispatch = useDispatch();
  const isUserLogin = useSelector(state => state.loginReducer.isUserLogin)
  const handleOnIdle = async(event) => {
    try{
      if(isUserLogin){
        console.log('datastore clear')
        await DataStore.clear();
        dispatch(logoutUser());
      }
    }catch(e){
      console.log('error in logout user', e)
    }
  }

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 250
  })


  return (
      <PersistGate loading={null} persistor={persistor}>
          <div className="App">
              <CustomDragLayer />
              <RouterConfig/>
          </div>
      </PersistGate>
  );
}
export default App;